import { PositionStudyGeneralInformation } from 'src/Services/API';

export const formatGeneralInformationForModification = (
  generalInformation: PositionStudyGeneralInformation,
  brandCode: string
) => {
  return {
    positionStudyName: generalInformation.positionStudyName ?? '',
    companyId: generalInformation.company?.companyId ?? '',
    qualificationId: generalInformation.qualification?.id ?? '',
    agencyId: generalInformation.agencyId,
    brandCode,
  };
};
