import { Circle } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { Cell, LabelList, Pie, PieChart, ResponsiveContainer } from 'recharts';

import { useFetchPositionStudiesStatistics } from 'src/Hooks/PositionStudies/useFetchPositionStudiesStatistics';

import styles from './GlobalStatistics.module.scss';
import { GlobalStatisticsProps } from './GlobalStatistics.types';

export const GlobalStatistics = ({ regions }: GlobalStatisticsProps) => {
  const { data: counts, isLoading } = useFetchPositionStudiesStatistics(regions);

  return isLoading ? (
    <div className={styles.illustration}>
      <Loader size="large" />
      chargement des études de poste
    </div>
  ) : !counts ? (
    <div className={styles.illustration}>
      <ServerErrorRed />
      désolé, une erreur s'est produite
    </div>
  ) : !counts.total ? (
    <div className={styles.illustration}>
      <VidePasteque />
      aucune edp trouvée sur ce périmètre
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.indicator}>
        <div>nombre total d'études de poste</div>
        <div className={styles.figure}>{counts.total}</div>
        <div>nombre de tous les edp à l'instant “t”</div>
      </div>
      <div className={styles.chartContainer}>
        <div className={styles.chartTitle}>
          répartition des différents statuts des études de postes
        </div>
        <div className={styles.chart}>
          <div className={styles.responsiveContainer}>
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={[
                    { count: counts.missingInfo },
                    { count: counts.pendingUpdate },
                    { count: counts.upToDate },
                  ].filter(data => data.count)}
                  dataKey="count"
                  innerRadius="50%"
                  outerRadius="100%"
                  stroke="none"
                  startAngle={270}
                  endAngle={-90}
                  paddingAngle={-0.5}
                >
                  {counts.missingInfo && <Cell className={styles.missingInfo} />}
                  {counts.pendingUpdate && <Cell className={styles.pendingUpdate} />}
                  {counts.upToDate && <Cell className={styles.upToDate} />}
                  <LabelList dataKey="count" position="inside" className={styles.label} />
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className={styles.legend}>
            <Circle variant="fill" className={styles.upToDate} />
            {Math.round((counts.upToDate / counts.total) * 100)}% soit {counts.upToDate} edp à jour
            <Circle variant="fill" className={styles.pendingUpdate} />
            {Math.round((counts.pendingUpdate / counts.total) * 100)}% soit {counts.pendingUpdate}{' '}
            edp à mettre à jour
            <Circle variant="fill" className={styles.missingInfo} />
            {Math.round((counts.missingInfo / counts.total) * 100)}% soit {counts.missingInfo} edp
            avec informations manquantes
          </div>
        </div>
      </div>
    </div>
  );
};
