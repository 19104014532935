import { Animation, BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { useQueryClient } from '@tanstack/react-query';
import classnames from 'classnames';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Router, Switch, useHistory, useParams } from 'react-router-dom';

import { QueryKeys } from 'src/Hooks/types';
import { getRequiredFields } from 'src/Redux/CompletionStatus/Selectors';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';

import { navigation } from '../PositionStudyModification.types';
import { PositionStudyModificationContext } from '../PositionStudyModificationContext';

import styles from './Sidebar.module.scss';

const Sidebar = () => {
  const history = useHistory();
  const isMobile = useSelector(getDevice);
  const queryClient = useQueryClient();
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const { data } = positionStudyModificationContext.fetchPositionStudy;
  const edpName = data?.generalInformation?.positionStudyName;
  const params = useParams<{
    firstSection: string;
    secondSection: string;
  }>();
  const requiredFields = useSelector(getRequiredFields);

  return (
    <div className={styles.container}>
      <Animation.Unroll visible={edpName !== undefined && !isMobile}>
        <div className={styles.titleContainer}>
          <p className={styles.title}>vous êtes sur l'edp :</p>
          <p className={styles.name}>{edpName}</p>
        </div>
      </Animation.Unroll>
      <div className={styles.linkContainer}>
        <Router history={history}>
          <Switch>
            {Object.values(navigation).map(node => (
              <Route key={node.name} path={`${node.path}(.*)`}>
                <Route exact path={node.path}>
                  <Redirect to={node.default} />
                </Route>

                {node.routes.map((route, idx) => {
                  const isCurrentRoute = route.linkTo.includes(params.secondSection);
                  const firstSection =
                    requiredFields[params.firstSection as keyof typeof requiredFields];
                  let secondSection = {
                    hr: {
                      profile: { positionStatus: false, practice: false, weekHours: false },
                      usefulinfo: {
                        selectedSector: false,
                        contactId: false,
                        zipCode: false,
                      },
                    },
                    security: {
                      risks: { risks: false },
                      protectionEquipment: { hasEpis: false, hasEpcs: false },
                      instructions: { hasHomeFormalisation: false },
                    },
                    job: { skills: false },
                  };
                  if (firstSection) {
                    secondSection =
                      firstSection[route.linkTo.substring(2) as keyof typeof firstSection];
                  }

                  let truthyCount = '0';
                  if (secondSection) {
                    truthyCount = Object.values(secondSection).filter(Boolean).length.toString();
                  }

                  return (
                    <div
                      key={route.name}
                      className={classnames(styles.link, {
                        [styles.active]: isCurrentRoute,
                      })}
                      onClick={() => {
                        if (positionStudyModificationContext.updateMutation.isLoading) {
                          queryClient.removeQueries({
                            queryKey: [QueryKeys.fetchPositionStudyById],
                          });
                          queryClient.removeQueries({
                            queryKey: [QueryKeys.fetchPositionStudyFromURL],
                          });
                        }
                        history.push(route.linkTo);
                      }}
                    >
                      <div
                        className={classnames(styles.textWrapper, {
                          [styles.noTopMargin]: idx === 0,
                        })}
                      >
                        <div className={styles.navNumber}>{`${idx + 1}.`}</div>
                        <div>{`${route.name}`}</div>
                      </div>
                      <div className={styles.textAndBadgeWrapper}>
                        <div className={styles.badgeWrapper}>
                          {firstSection && secondSection && truthyCount !== '0' && (
                            <BadgeDeprecated
                              value={truthyCount}
                              className={classnames(styles.dot, {
                                [styles.firstDotMargin]: idx === 0,
                              })}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Route>
            ))}
          </Switch>
        </Router>
      </div>
    </div>
  );
};

export default Sidebar;
