import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { BadgeDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import {
  ArrowForward,
  FlecheBack,
  RedBullet,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { getRequiredFields } from 'src/Redux/CompletionStatus/Selectors';
import { getEditionRoute } from 'src/Services/Routing';

import styles from '../MobilePositionStudyModification/MobilePositionStudyModification.module.scss';
import {
  getCorrectAscendingTab,
  getCorrectDescendingTab,
  navigation,
  SectionsRootPath,
} from '../PositionStudyModification.types';
import { checkTruthOfRequiredFields } from '../utils';

const MobilePositionStudyNavigation = () => {
  const history = useHistory();
  const params = useParams<{
    firstSection: string;
    companyId: string;
    positionStudyId: string;
    secondSection: string;
  }>();

  const requiredFields = useSelector(getRequiredFields);

  const [mobileActiveTab, setMobileActiveTab] = useState<string>(
    Object.values(navigation).find(section =>
      history.location.pathname.includes(section.default.substring(1))
    )?.name ?? ''
  );

  const [isRedBulletDisplayed, setIsRedBulletDisplayed] = useState(false);

  useEffect(() => {
    setMobileActiveTab(
      Object.values(navigation).find(section =>
        history.location.pathname.includes(
          section.default.substring(1, section.default.lastIndexOf('/'))
        )
      )?.name ?? ''
    );
    setIsRedBulletDisplayed(
      checkTruthOfRequiredFields(requiredFields[params.firstSection as keyof typeof requiredFields])
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname, requiredFields]);

  const mobileNavigationTabs = useCallback(
    (nextTab: boolean) => {
      history.push(
        getEditionRoute({
          companyId: params.companyId,
          positionStudyId: params.positionStudyId,
          section: nextTab
            ? getCorrectAscendingTab(
                SectionsRootPath[params.firstSection as keyof typeof SectionsRootPath]
              )
            : getCorrectDescendingTab(
                SectionsRootPath[params.firstSection as keyof typeof SectionsRootPath]
              ),
        })
      );

      setMobileActiveTab(
        Object.values(navigation).find(section =>
          section.default.includes(
            nextTab
              ? getCorrectAscendingTab(
                  SectionsRootPath[params.firstSection as keyof typeof SectionsRootPath]
                )
              : getCorrectDescendingTab(
                  SectionsRootPath[params.firstSection as keyof typeof SectionsRootPath]
                )
          )
        )?.name ?? ''
      );
    },
    [history, params]
  );

  const handleMobileSubsectionChange = (event: React.SyntheticEvent, newValue: number) => {
    history.push(
      history.location.pathname.substr(0, history.location.pathname.lastIndexOf('/')) +
        Object.values(navigation)
          .find(section => section.name === mobileActiveTab)
          ?.routes[newValue].linkTo.substring(1) ?? '/'
    );
  };

  return (
    <div className={styles.mobileTabsContainer}>
      <div className={styles.mobileTabsSection}>
        <div className={styles.mobileTitle}>
          {mobileActiveTab} {isRedBulletDisplayed && <RedBullet />}
        </div>
        <div className={styles.mobileNavigationArrows}>
          <FlecheBack
            className={classnames(styles.mobileArrow, styles.icon, {
              [styles.disabled]: mobileActiveTab === 'ressources humaines',
            })}
            onClick={() =>
              mobileActiveTab !== 'ressources humaines' ? mobileNavigationTabs(false) : {}
            }
          />

          <ArrowForward
            className={classnames(styles.mobileArrow, styles.icon, {
              [styles.disabled]: mobileActiveTab === 'sécurité',
            })}
            onClick={() => (mobileActiveTab !== 'sécurité' ? mobileNavigationTabs(true) : {})}
          />
        </div>
      </div>
      <Tabs
        value={
          Object.values(navigation)
            .find(section => section.name === mobileActiveTab)
            ?.routes.findIndex(subSection => {
              return history.location.pathname.includes(subSection.linkTo.substring(1));
            }) ?? 0
        }
        onChange={handleMobileSubsectionChange}
        variant="scrollable"
        scrollButtons={false}
        allowScrollButtonsMobile
        aria-label="scrollable tabs"
        sx={{
          marginLeft: '-1rem',
          marginRight: '-1rem',
          '& .MuiTabs-indicator': {
            backgroundColor: '#0f1941', // main-navy-full
            height: '0.25rem',
          },
        }}
      >
        {Object.values(navigation)
          .find(section => section.name === mobileActiveTab)
          ?.routes.map((subSection, index) => {
            let truthyCount = '0';
            if (subSection) {
              const firstSection =
                requiredFields[params.firstSection as keyof typeof requiredFields];
              let secondSection = {};
              if (firstSection) {
                secondSection =
                  firstSection[subSection.linkTo.substring(2) as keyof typeof firstSection];
              }
              if (secondSection) {
                truthyCount = Object.values(secondSection).filter(Boolean).length.toString();
              }
            }
            return (
              <Tab
                key={`${index} ${subSection.name}`}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ textTransform: 'lowercase', fontSize: '1rem' }}>
                      {subSection.name}
                    </span>
                    {subSection !== undefined &&
                    params.secondSection !== 'skills' &&
                    truthyCount !== '0' ? (
                      <BadgeDeprecated value={truthyCount} className={classnames(styles.dot)} />
                    ) : null}
                  </div>
                }
                sx={{
                  color: '#2175d9', // main-blue-full
                  textTransform: 'lowercase',
                  fontSize: '1rem',
                  '&.Mui-selected': {
                    '& span': {
                      color: '#0f1941', // main-navy-full
                    },
                  },
                }}
              />
            );
          })}
      </Tabs>
      <div className={styles.horizontalSeparator} />
    </div>
  );
};

export default MobilePositionStudyNavigation;
