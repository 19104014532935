import {
  Button,
  DropDown,
  ModalDeprecated,
  PopupActions,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { HighVoltage, LowVoltage } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import {
  ErrorMessage,
  useFormWithZodResolver,
} from '@randstad-lean-mobile-factory/react-form-fields';
import classNames from 'classnames';
import React from 'react';
import { useController } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { IllusEDPHabilitations } from 'src/Assets';
import { SelectionIconButtonWithMobile } from 'src/Components/SelectionIconButtonWithMobile';
import { AddButton } from 'src/Containers/RH/Profile/Components/AddButton';
import { useFetchElectricHabilitations } from 'src/Hooks/Training/useFetchElectricHabilitations';

import { getDevice } from '../../../Redux/UserPreferences/Selectors';

import styles from './ElectricHabilitationModal.module.scss';
import { electricHabilitationSchema, Props } from './ElectricHabilitationModal.types';

const ElectricHabilitationModal = ({ onValidate, currentHabilitations }: Props) => {
  const innerRef = React.useRef<PopupActions>(null);
  const { data } = useFetchElectricHabilitations();
  const isMobile = useSelector(getDevice);

  const electricHabilitationZodResolver = useFormWithZodResolver({
    schema: electricHabilitationSchema,
    defaultValues: {
      tension: undefined,
      current: undefined,
      currentType: undefined,
      selectedHabilitation: undefined,
      currentHabilitations: currentHabilitations,
    },
  });

  const voltageZodController = useController({
    name: 'tension',
    control: electricHabilitationZodResolver.control,
  });
  const currentZodController = useController({
    name: 'current',
    control: electricHabilitationZodResolver.control,
  });
  const currentTypeZodController = useController({
    name: 'currentType',
    control: electricHabilitationZodResolver.control,
  });
  const selectedHabilitationZodController = useController({
    name: 'selectedHabilitation',
    control: electricHabilitationZodResolver.control,
  });
  const currentHabilitationsZodController = useController({
    name: 'currentHabilitations',
    control: electricHabilitationZodResolver.control,
  });

  const proposedCurrents = data?.find(
    item => item.currentId === voltageZodController.field.value
  )?.levels;
  const proposedHabilitationTypes = proposedCurrents
    ?.find(item => item.categoryLabel === currentZodController.field.value)
    ?.currents.map(current => current.key);
  return (
    <ModalDeprecated
      ref={innerRef}
      icon={<IllusEDPHabilitations />}
      title="habilitation"
      onClose={() => electricHabilitationZodResolver.reset()}
      trigger={<AddButton text={'ajouter une habilitation électrique'} />}
      footerActionsLeft={[
        <Button.Tertiary onClick={() => electricHabilitationZodResolver.reset()}>
          tout réinitialiser
        </Button.Tertiary>,
      ]}
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            innerRef.current?.close();
          }}
        >
          annuler
        </Button.Secondary>,
        <Button.Primary
          disabled={
            currentTypeZodController.field.value === undefined ||
            currentHabilitationsZodController.field.value === undefined ||
            selectedHabilitationZodController.field.value === undefined
          }
          onClick={electricHabilitationZodResolver.handleSubmit(() => {
            const selectedHabilitation = selectedHabilitationZodController.field.value;
            selectedHabilitation && onValidate(selectedHabilitation);
            innerRef.current?.close();
          })}
        >
          valider
        </Button.Primary>,
      ]}
    >
      <>
        <WithLightTitle title="choix de la tension">
          <div className={styles.voltageSelection}>
            {data?.map((voltage, index) => {
              const isSelected = voltageZodController.field.value === voltage.currentId;
              return (
                <SelectionIconButtonWithMobile.Component
                  key={index}
                  label={voltage.value}
                  icon={voltage.currentId === 'B' ? <LowVoltage /> : <HighVoltage />}
                  selected={isSelected}
                  onClick={() => {
                    currentTypeZodController.field.onChange(undefined);
                    currentZodController.field.onChange(undefined);
                    voltageZodController.field.onChange(voltage.currentId);
                  }}
                  className={isMobile ? styles.mobileVoltageButton : styles.voltageButton}
                />
              );
            })}
            <ErrorMessage error={voltageZodController.fieldState.error} />
          </div>
        </WithLightTitle>
        {voltageZodController.field.value && (
          <div className={isMobile ? styles.mobileDropDowns : styles.dropDowns}>
            <WithLightTitle title="niveau d'habilitation éléctriques">
              <div className={isMobile ? styles.mobileDropDown : styles.dropDown}>
                <DropDown
                  placeholder={
                    isMobile
                      ? "choisir un niveau d'habilitation"
                      : "choisir un niveau d'habilitation éléctriques"
                  }
                  childrenClassName={classNames({ [styles.mobileDropDownChildren]: isMobile })}
                  items={proposedCurrents?.map(level => level.categoryLabel) ?? []}
                  onSelectItem={(item?: string) => {
                    currentZodController.field.onChange(item);
                    currentTypeZodController.field.onChange(undefined);
                    selectedHabilitationZodController.field.onChange(undefined);
                  }}
                  keyValueExtractor={(searchResult: string) => ({
                    key: searchResult,
                    value: searchResult,
                  })}
                  selectedItem={currentZodController.field.value}
                />
                <ErrorMessage error={currentZodController.fieldState.error} />
              </div>
            </WithLightTitle>
            <WithLightTitle title="type de courant">
              <div className={isMobile ? styles.mobileDropDown : styles.dropDown}>
                <DropDown
                  disabled={currentZodController.field.value === undefined}
                  placeholder="choisir un courant"
                  childrenClassName={classNames({ [styles.mobileDropDownChildrenTwo]: isMobile })}
                  items={proposedHabilitationTypes ?? []}
                  onSelectItem={(item?: string) => {
                    currentTypeZodController.field.onChange(item);
                    currentHabilitationsZodController.field.onChange(currentHabilitations);
                    const selectedHabilitation = proposedCurrents
                      ?.find(
                        currentCategory =>
                          currentCategory.categoryLabel === currentZodController.field.value
                      )
                      ?.currents.find(current => current.key === item)?.electricHabilitation;
                    selectedHabilitationZodController.field.onChange(selectedHabilitation);
                  }}
                  keyValueExtractor={(searchResult: string) => ({
                    key: searchResult,
                    value: searchResult,
                  })}
                  selectedItem={currentTypeZodController.field.value}
                />
              </div>
              <ErrorMessage error={currentTypeZodController.fieldState.error} />
            </WithLightTitle>
          </div>
        )}
        <ErrorMessage error={currentHabilitationsZodController.fieldState.error} />
      </>
    </ModalDeprecated>
  );
};

export default ElectricHabilitationModal;
