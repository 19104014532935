import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { QueryKeys } from 'src/Hooks/types';
import { queryClient } from 'src/Providers/ReactQuery';
import { ConsultantPerimeter, PerimeterCompany } from 'src/Services/API';

import { PerimeterState } from './Types';

const initialState: PerimeterState = {
  currentBrand: undefined as never,
  currentAgency: undefined as never,
};

export const { reducer: perimeterReducer, actions: perimeterActions } = createSlice({
  name: 'perimeter',
  initialState,
  reducers: {
    setSelectedPerimeter: (
      state,
      action: PayloadAction<{ brand: PerimeterCompany; agencyId: string }>
    ) => {
      if (
        state.currentBrand?.brandCodeApiHeader !== action.payload.brand.brandCodeApiHeader ||
        state.currentAgency !== action.payload.agencyId
      ) {
        queryClient.resetQueries({
          predicate: query => query.queryKey[0] !== QueryKeys.fetchPerimeter,
        });
        queryClient.removeQueries({
          predicate: query => query.queryKey[0] !== QueryKeys.fetchPerimeter,
        });
      }

      state.currentBrand = action.payload.brand;
      state.currentAgency = action.payload.agencyId;
    },
    clearPerimeter: state => {
      state.currentBrand = undefined as never;
      state.currentAgency = undefined as never;
    },
    setUserInfo: (state, action: PayloadAction<ConsultantPerimeter>) => {
      state.regionId = action.payload.regionId;
      state.zoneId = action.payload.zoneId;
      state.branchId = action.payload.branchId;
    },
  },
});
