import { Check } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import { useCallback } from 'react';

import styles from './CustomDropdownOption.module.scss';
import Props from './CustomDropdownOption.types';

const CustomDropdownOption = <T,>({ data, isSelected, setValue }: Props<T>) => {
  const selectOption = useCallback(() => setValue(data, 'select-option'), [data, setValue]);

  return (
    <div
      className={classnames(
        styles.customDropdownOption,
        isSelected && styles.selected,
        data.className
      )}
      onClick={selectOption}
    >
      {data.icon && <div className={styles.leftIcon}>{data.icon}</div>}
      <div className={styles.label}>{data.label}</div>
      {isSelected && <Check className={styles.rightIcon} />}
    </div>
  );
};

export default CustomDropdownOption;
