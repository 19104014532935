import { useQuery } from '@tanstack/react-query';

import { PositionstudiesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchPositionStudiesStatistics = (
  regions: { regionId: string; regionTitle: string; agencyIds: string[] }[]
) => {
  return useQuery(
    [QueryKeys.fetchPositionStudiesStatistics, regions],
    async () => {
      return await PositionstudiesService.positionStudiesControllerGetPositionStudiesStatistics({
        body: { agencyIds: regions.flatMap(region => region.agencyIds) },
      });
    },
    {
      enabled: !!regions.length,
    }
  );
};
