import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { DetailedPositionStudy, PositionstudiesService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchPositionStudyById = (
  positionStudyId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraOptions?: UseQueryOptions<DetailedPositionStudy, unknown, DetailedPositionStudy, any>
) => {
  const currentBrand = useSelector(getCurrentBrand);

  return useQuery(
    [QueryKeys.fetchPositionStudyById, positionStudyId, currentBrand?.brandCodeApiHeader],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const positionStudy =
        await PositionstudiesService.positionStudiesControllerGetPositionStudyById({
          brandCode: currentBrand.brandCodeApiHeader,
          positionStudyId,
        });
      return positionStudy;
    },
    {
      refetchOnWindowFocus: false,
      ...extraOptions,
    }
  );
};
