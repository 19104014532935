import { FindPeople, Position } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import {
  Avatar,
  RandstadMobileAppsPopup,
} from '@randstad-lean-mobile-factory/react-components-core';
import {
  EDPIcon,
  Grid,
  WhiteCross,
  OpenLock,
  StraightBurgerMenu,
  RandstadDisk,
} from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useState } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { PerimeterSelectionModal } from 'src/Containers/Modals/PerimeterSelectionModal';
import useAuth from 'src/Hooks/Authentication/useAuth';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { getDashboardRoute, getStudiesRoute } from 'src/Services/Routing';
import { ScrollDirection, useScrollDirection } from 'src/Utils/detectScrollDirection';

import styles from './MobileTopBar.module.scss';

const MobileTopBar = () => {
  const history = useHistory();
  const route = useRouteMatch<{ route: 'studies' | 'dashboard' }>('/:route');

  const { givenName, familyName, email } = useAuthenticatedUser();
  const { isProd } = useIsProductionEnvironment();
  const scrollDirection = useScrollDirection('formContainer');

  const brand = useSelector(getCurrentBrand);
  const [isAppLinkOpen, setIsAppLinkOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const { user, logout } = useAuth();

  const [isPerimeterSelectionOpen, setIsPerimeterSelectionOpen] = useState(false);

  return (
    <div
      className={classnames(styles.container, {
        [styles.hideTopBar]: scrollDirection === ScrollDirection.DOWN,
      })}
    >
      {scrollDirection !== ScrollDirection.DOWN && (
        <Menu
          isOpen={isOpen}
          customBurgerIcon={<StraightBurgerMenu />}
          customCrossIcon={<WhiteCross />}
          onClose={() => {
            setIsAppLinkOpen(false);
            setIsOpen(false);
          }}
          onOpen={() => setIsOpen(true)}
        >
          <div className={styles.menuContainer}>
            {isAppLinkOpen ? (
              <RandstadMobileAppsPopup
                isProd={isProd}
                onArrowClick={() => setIsAppLinkOpen(false)}
                brandCode={brand?.brand?.code}
              />
            ) : (
              <div>
                <div className={styles.userInfo}>
                  <Avatar size="xsmall" color="blue" content={user} />
                  <div>
                    <p className={styles.whiteText}>
                      {givenName} {familyName}
                    </p>
                    <p className={styles.greyText}>{email}</p>
                  </div>
                </div>
                <div className={styles.lineSeparator} />
                <div
                  className={classnames(styles.menuItem, styles.firstMenuItem, {
                    [styles.selectedMenuItem]: route?.params.route === 'studies',
                  })}
                  onClick={() => {
                    history.push(getStudiesRoute());
                    setIsOpen(false);
                  }}
                >
                  <div className={styles.circle}>
                    <Position
                      variant={route?.params.route === 'studies' ? 'fill' : 'line'}
                      className={styles.gridIcon}
                    />
                  </div>
                  <div>
                    <p className={styles.whiteText}>pilotage</p>
                  </div>
                </div>
                <div
                  className={classnames(styles.menuItem, styles.lastMenuItem, {
                    [styles.selectedMenuItem]: route?.params.route === 'dashboard',
                  })}
                  onClick={() => {
                    history.push(getDashboardRoute());
                    setIsOpen(false);
                  }}
                >
                  <div className={styles.circle}>
                    <FindPeople
                      variant={route?.params.route === 'dashboard' ? 'fill' : 'line'}
                      className={styles.gridIcon}
                    />
                  </div>
                  <div>
                    <p className={styles.whiteText}>inter apps</p>
                  </div>
                </div>
                <div className={styles.lineSeparator} />
                <div
                  className={classnames(styles.menuItem, styles.firstMenuItem)}
                  onClick={() => {
                    setIsOpen(false);
                    setIsPerimeterSelectionOpen(true);
                  }}
                >
                  <div className={styles.circle}>
                    <RandstadDisk className={styles.gridIcon} />
                  </div>
                  <div>
                    <p className={styles.whiteText}>choix unités</p>
                    <p className={styles.greyText}>définissez votre périmètre</p>
                  </div>
                </div>
                <div
                  className={classnames(styles.menuItem, styles.lastMenuItem)}
                  onClick={() => setIsAppLinkOpen(true)}
                >
                  <div className={styles.circle}>
                    <Grid className={styles.gridIcon} />
                  </div>
                  <div>
                    <p className={styles.whiteText}>inter apps</p>
                    <p className={styles.greyText}>naviguer parmi les applications Randstad</p>
                  </div>
                </div>
                <div className={styles.lineSeparator} />
                <div
                  className={classnames(styles.menuItem, styles.firstMenuItem)}
                  onClick={() => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })}
                >
                  <div className={styles.circle}>
                    <OpenLock className={styles.gridIcon} />
                  </div>
                  <div>
                    <p className={styles.whiteText}>déconnexion</p>
                    <p className={styles.greyText}>fermer la session</p>
                  </div>
                </div>
              </div>
            )}

            <div>
              <div className={styles.lineSeparator} />
              <p className={styles.version}>
                {process.env.REACT_APP_VERSION &&
                  `version de l'app : ${process.env.REACT_APP_VERSION}`}
              </p>
            </div>
          </div>
        </Menu>
      )}

      <div className={styles.topBarComponent}>
        <EDPIcon />
        <div className={styles.separator} />
        <h1 className={styles.title}>étude de poste</h1>
      </div>
      <PerimeterSelectionModal
        open={isPerimeterSelectionOpen}
        onClose={() => setIsPerimeterSelectionOpen(false)}
      />
    </div>
  );
};
export default MobileTopBar;
