import { UnitesGreenish } from '@randstad-lean-mobile-factory/react-assets/dist/headers';
import {
  Button,
  Modal,
  ModalActions,
  ModalContent,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import invariant from 'invariant';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SearchableDropDown from 'src/Components/SearchableDropDown';
import { useFetchPerimeter } from 'src/Hooks/Consultants/useFetchPerimeter';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { perimeterActions } from 'src/Redux/Perimeter/Slice';
import { PerimeterCompany } from 'src/Services/API';

import { PerimeterSelectionModalProps } from './PerimeterSelectionModal.types';

const PerimeterSelectionModalContent = ({ onClose }: PerimeterSelectionModalProps) => {
  const { data: perimeter } = useFetchPerimeter();
  invariant(perimeter, 'perimeter should be defined');

  const dispatch = useDispatch();

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);

  const [initialBrand, initialAgency] = useMemo((): [PerimeterCompany, string] => {
    if (currentBrand && currentAgency) {
      if (currentBrand.agencies.includes(currentAgency)) return [currentBrand, currentAgency];
    }
    const defaultAgencyId = perimeter.defaultAgencyId;
    if (defaultAgencyId) {
      const brand = perimeter.companies.find(company => company.agencies.includes(defaultAgencyId));
      if (brand) return [brand, defaultAgencyId];
    }
    return [perimeter.companies[0], perimeter.companies[0].agencies[0]];
  }, [currentAgency, currentBrand, perimeter.companies, perimeter.defaultAgencyId]);

  const [selectedBrand, setSelectedBrand] = useState<PerimeterCompany>(initialBrand);
  const [selectedAgency, setSelectedAgency] = useState<string>(initialAgency);

  return (
    <>
      <ModalContent header={<UnitesGreenish />} title="sélectionner une agence">
        <WithLightTitle title="marque">
          <SearchableDropDown
            items={perimeter.companies}
            selectedItem={selectedBrand}
            keyValueExtractor={item => {
              return {
                key: item.brandCodeApiHeader,
                label: item.name,
                value: item.brandCodeApiHeader,
                item,
              };
            }}
            onSelectItem={item => {
              if (!item) return;
              setSelectedBrand(item);
              if (!item.agencies.includes(currentAgency)) setSelectedAgency(item.agencies[0]);
            }}
            placeholder="sélectionner une marque"
          />
        </WithLightTitle>
        <WithLightTitle title="agence">
          <SearchableDropDown
            disabled={!selectedBrand}
            items={selectedBrand?.agencies ?? []}
            selectedItem={selectedAgency}
            onSelectItem={agencyId => {
              if (!agencyId) return;
              setSelectedAgency(agencyId);
            }}
            placeholder="sélectionner une agence"
          />
        </WithLightTitle>
      </ModalContent>
      <ModalActions side="left">
        <Button
          variant="tertiary"
          onClick={() => {
            setSelectedBrand(initialBrand);
            setSelectedAgency(initialAgency);
          }}
        >
          réinitialiser
        </Button>
      </ModalActions>
      <ModalActions side="right">
        {Boolean(currentBrand && currentAgency) && (
          <Button variant="secondary" onClick={onClose}>
            annuler
          </Button>
        )}
        <Button
          onClick={() => {
            dispatch(
              perimeterActions.setSelectedPerimeter({
                brand: selectedBrand,
                agencyId: selectedAgency,
              })
            );
            onClose();
          }}
        >
          valider
        </Button>
      </ModalActions>
    </>
  );
};

export const PerimeterSelectionModal = ({ open, onClose }: PerimeterSelectionModalProps) => {
  const { data: perimeter } = useFetchPerimeter();
  invariant(perimeter, 'perimeter should be defined');

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);

  return (
    <Modal
      size="medium"
      open={open}
      onClose={onClose}
      disableCloseButton={!currentBrand || !currentAgency}
    >
      <PerimeterSelectionModalContent open={open} onClose={onClose} />
    </Modal>
  );
};
