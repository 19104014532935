import { ToastContainer } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Route, Router, useHistory, useLocation } from 'react-router-dom';

import history from 'src/Utils/history';

import { Login } from './Containers/Login';
import { useShowLoginScreen } from './Containers/Login/Login.types';
import MainPage from './Containers/MainPage';
import EDPDuplicationModal from './Containers/Modals/EDPCreationModal/EDPDuplicationModal';
import EDPNewCreationModal from './Containers/Modals/EDPCreationModal/EDPNewCreationModal';
import EDPRenameModal from './Containers/Modals/EDPRenameModal/EDPRenameModal.enhanced';
import useInitializeAnalytics from './Hooks/Analytics/useInitializeAnalytics';
import useAuth from './Hooks/Authentication/useAuth';
import { userPreferencesActions } from './Redux/UserPreferences/Slice';
import { setupAPI } from './Services/API/APIConfigurator';
import { security } from './Services/API/Interceptors/security';
import { getStudiesRoute, LocationState, ModalRoute } from './Services/Routing';
import DebugTools from './Utils/DebugTools';

const modalRouteMap: ModalRoute[] = [
  { route: 'create-study', component: EDPNewCreationModal },
  { route: 'duplicate-study', component: EDPDuplicationModal },
  { route: 'rename', component: EDPRenameModal },
];

function ModalSwitch() {
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const history = useHistory();

  const showLoginScreen = useShowLoginScreen();

  if (
    !background &&
    modalRouteMap.some(
      modalRoute => modalRoute.route === location.pathname && !modalRoute.backgroundIsOptional
    )
  ) {
    history.push(getStudiesRoute());
  }

  if (showLoginScreen) return <Login />;

  return (
    <DebugTools>
      <MainPage />

      {modalRouteMap.map(modalInfo => (
        <Route
          key={modalInfo.route}
          path={`(.*)/${modalInfo.route}`}
          children={<modalInfo.component modalParameters={locationState?.modalParameters} />}
        />
      ))}

      <ToastContainer position="bottom-right" />
    </DebugTools>
  );
}

function App() {
  useInitializeAnalytics();

  const { getAccessTokenSilently, logout } = useAuth();
  security.setAccessTokenSilently(getAccessTokenSilently);
  security.setLogout(logout);
  const dispatch = useDispatch();
  useEffect(() => setupAPI(), []);

  useEffect(() => {
    console.log('on mount APP');
  }, []);
  const isDesktop = useMediaQuery({
    query: '(min-device-width: 768px)',
  });
  useEffect(() => {
    dispatch(userPreferencesActions.setDevice(!isDesktop));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);
  return (
    <div className="App">
      <Router history={history}>
        <ModalSwitch />
      </Router>
    </div>
  );
}

export default App;
