import {
  BadgeDeprecated,
  SegmentedMenu,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import { BlueBinocular } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import CompanyPicker from 'src/Components/CompanyPicker';
import { useAuthenticatedUser } from 'src/Hooks/Authentication/useAuthenticatedUser';
import { useFetchPositionStudies } from 'src/Hooks/PositionStudies/useFetchPositionStudies';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { getCompanyRoute } from 'src/Services/Routing';

import styles from './Home.module.scss';
import { EnumViewType } from './Home.types';
import { LastPositionStudies } from './LastPositionStudies';

const Home = () => {
  const { givenName } = useAuthenticatedUser();
  const history = useHistory();
  const isMobile = useSelector(getDevice);
  const [view, setView] = useState<EnumViewType>(EnumViewType.LATEST);
  const [count, setCount] = useState<number>(0);

  // Very big screen (31inch) is approximatively 3 rows of 8 cards
  const fetchPositionStudiesResult = useFetchPositionStudies(
    {},
    view === EnumViewType.LATEST,
    view
  );
  const fetchPositionStudiesResultLatest = useFetchPositionStudies({}, true, EnumViewType.LATEST);
  const fetchPositionStudiesResultAll = useFetchPositionStudies({}, false, EnumViewType.ALL);

  useEffect(() => {
    if (
      !fetchPositionStudiesResultLatest.isFetching &&
      fetchPositionStudiesResultLatest.data &&
      fetchPositionStudiesResultLatest.data?.pages[0]?.count !== count
    ) {
      setCount(fetchPositionStudiesResultLatest.data?.pages[0]?.count ?? 0);
    } else if (
      !fetchPositionStudiesResultAll.isFetching &&
      fetchPositionStudiesResultAll.data &&
      fetchPositionStudiesResultAll.data?.pages[0]?.count !== count
    ) {
      setCount(fetchPositionStudiesResultAll.data?.pages[0]?.count ?? 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchPositionStudiesResultLatest.data, fetchPositionStudiesResultAll.data, count]);

  const displayedPositionStudiesCount = fetchPositionStudiesResult.countDisplayedStudies;

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        <div className={styles.searchContainer}>
          {!isMobile && <div className={styles.hello}>Bonjour {givenName} !</div>}
          <div className={classnames(styles.title, { [styles.mobileTitle]: isMobile })}>{`créer ${
            !isMobile ? 'ou' : '/'
          } retrouver une edp`}</div>
          <WithLightTitle title="de quel compte s'agit-il ?">
            <CompanyPicker
              className={styles.companyPicker}
              onCompanyChange={company =>
                history.push({
                  pathname: getCompanyRoute({ companyId: company.companyId }),
                  state: {
                    routeParameters: {
                      companyId: company.companyId,
                      companyName: company.companyName,
                    },
                  },
                })
              }
            />
          </WithLightTitle>
          <SegmentedMenu
            controls={[EnumViewType.LATEST, EnumViewType.ALL]}
            className={styles.menu}
            separatorClassName={styles.separator}
            controlItemClassName={styles.titles}
            selected={view}
            keyValueExtractor={key => {
              return {
                key: key,
                value: key === EnumViewType.LATEST ? 'dernières edp' : 'toutes les edp',
                rightIcon:
                  key === EnumViewType.ALL &&
                  fetchPositionStudiesResult.isSuccess &&
                  !fetchPositionStudiesResult.isError ? (
                    <BadgeDeprecated
                      value={`${count}`}
                      className={classnames(
                        styles.positionStudiesCounter,
                        {
                          [styles.selectedAll]: view === EnumViewType.ALL,
                        },
                        count > 10 ? styles.counterTens : '',
                        count > 100 ? styles.counterHundreds : ''
                      )}
                    />
                  ) : undefined,
              };
            }}
            onSelectionChange={newSelectedView => {
              setView(newSelectedView);
            }}
            displayVerticalSeparator
            verticalSeparatorClassName={styles.verticalSeparator}
            bottomBarClassName={classnames(
              { [styles.leftBottomBar]: view === EnumViewType.LATEST },
              { [styles.rightBottomBar]: view === EnumViewType.ALL && count < 100 },
              { [styles.rightBottomBarHundreds]: view === EnumViewType.ALL && count >= 100 }
            )}
          />
        </div>
        <BlueBinocular
          className={classnames({ [styles.icon]: !isMobile, [styles.mobileIcon]: isMobile })}
        />
      </div>
      <LastPositionStudies
        fetchPositionStudiesResult={fetchPositionStudiesResult}
        totalPositionStudiesCount={count}
        displayedPositionStudiesCount={displayedPositionStudiesCount}
        view={view}
      />
    </div>
  );
};

export default Home;
