/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-nocheck
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { Expose, Transform, Type, plainToClass } from 'class-transformer';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(
  configs: IRequestConfig,
  resolve: (p: any) => void,
  reject: (p: any) => void
): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(
  method: string,
  contentType: string,
  url: string,
  options: any
): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType,
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T = any> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class ConsultantsService {
  /**
   *
   */
  static consultantsControllerGetPerimeter(
    options: IRequestOptions = {}
  ): Promise<ConsultantPerimeter> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/perimeter';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(ConsultantPerimeter, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static consultantsControllerGetConsultantAgenciesByRegion(
    options: IRequestOptions = {}
  ): Promise<EstablishmentRegion[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/agencies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(EstablishmentRegion, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static consultantsControllerHasConsultantGivenPhotoRights(
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/photoRights';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static consultantsControllerAddConsultantPhotoRights(
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/consultants/photoRights';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CompaniesService {
  /**
   *
   */
  static companiesControllerGetCompanies(
    params: {
      /** requestBody */
      body?: GetCompaniesBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanySearchResult[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(CompanySearchResult, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyDetail(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyDetails> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(CompanyDetails, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyWorkingLocations(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyAddress[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/workingLocation';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CompanyAddress, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyServices(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyService[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/services';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CompanyService, response)), reject);
    });
  }
  /**
   *
   */
  static companiesControllerGetCompanyContacts(
    params: {
      /**  */
      id: string;
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<CompanyContact[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/companies/{id}/contacts';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CompanyContact, response)), reject);
    });
  }
}

export class PositionstudiesService {
  /**
   *
   */
  static positionStudiesControllerGetPositionStudies(
    params: {
      /**  */
      agencyId: string;
      /**  */
      since?: string;
      /**  */
      offset?: number;
      /**  */
      limit?: number;
      /**  */
      brandCode: string;
      /**  */
      qualificationId?: string;
      /**  */
      companyId?: string;
      /**  */
      serviceId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionStudiesWithStatistics> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        since: params['since'],
        offset: params['offset'],
        limit: params['limit'],
        brandCode: params['brandCode'],
        qualificationId: params['qualificationId'],
        companyId: params['companyId'],
        serviceId: params['serviceId'],
      };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(PositionStudiesWithStatistics, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerCreatePositionStudy(
    params: {
      /** requestBody */
      body?: CreatePositionStudyParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedPositionStudy> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(DetailedPositionStudy, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerUpdatePositionStudy(
    params: {
      /** requestBody */
      body?: UpdatePositionStudyParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedPositionStudy> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(DetailedPositionStudy, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudiesCount(
    params: {
      /**  */
      agencyId: string;
      /**  */
      since?: string;
      /**  */
      brandCode: string;
      /**  */
      qualificationId?: string;
      /**  */
      companyId?: string;
      /**  */
      serviceId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<number> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/count';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        agencyId: params['agencyId'],
        since: params['since'],
        brandCode: params['brandCode'],
        qualificationId: params['qualificationId'],
        companyId: params['companyId'],
        serviceId: params['serviceId'],
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudyById(
    params: {
      /**  */
      brandCode: string;
      /**  */
      positionStudyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedPositionStudy> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/positionStudy';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        brandCode: params['brandCode'],
        positionStudyId: params['positionStudyId'],
      };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(DetailedPositionStudy, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetHasClientPhotoRights(
    params: {
      /**  */
      companyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<HasClientGivenRights> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/hasClientPhotoRights';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { companyId: params['companyId'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(HasClientGivenRights, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerDeletePositionStudy(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: DeletePositionStudyParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerUpdatePositionStudyName(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdatePositionStudyNameBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedPositionStudy> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/name';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(DetailedPositionStudy, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerDuplicateFullPositionStudy(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: DuplicateFullEDPBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedPositionStudy> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/duplicate';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(DetailedPositionStudy, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerDuplicatePositionStudyWithNewQualification(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: DuplicateEDPWithNewQualificationBody;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DetailedPositionStudy> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/duplicateWithNewQualification';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(DetailedPositionStudy, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudyFiles(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionStudyFile[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/files';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(PositionStudyFile, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerUploadPositionStudyFiles(
    params: {
      /**  */
      id: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionStudyFile> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/files';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(PositionStudyFile, response)), reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudyFileData(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/file/{id}/data';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudyPdf(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: GetPositionStudyPdfQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/pdf';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPreventionSheetPdf(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: GetPreventionSheetPdfQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/pdfPreventionSheet';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetSynthesisSheetPdf(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: GetSynthesisSheetPdfQueryParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/pdfSynthesisSheet';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerDeletePositionStudyFiles(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/file/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static positionStudiesControllerGetPositionStudiesStatistics(
    params: {
      /** requestBody */
      body?: GetPositionStudiesStatisticsParams;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PositionStudiesStatistics> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/statistics';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(PositionStudiesStatistics, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static positionStudiesControllerAddClientPhotoRights(
    params: {
      /**  */
      id: string;
      /**  */
      file: any;
      /**  */
      contactMail: string;
      /**  */
      contactLastName: string;
      /**  */
      contactFirstName: string;
      /**  */
      brandCode: string;
      /**  */
      companyId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/positionstudies/{id}/addClientPhotoRights';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);

      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      if (params['contactMail']) {
        if (Object.prototype.toString.call(params['contactMail']) === '[object Array]') {
          for (const item of params['contactMail']) {
            data.append('contactMail', item as any);
          }
        } else {
          data.append('contactMail', params['contactMail'] as any);
        }
      }

      if (params['contactLastName']) {
        if (Object.prototype.toString.call(params['contactLastName']) === '[object Array]') {
          for (const item of params['contactLastName']) {
            data.append('contactLastName', item as any);
          }
        } else {
          data.append('contactLastName', params['contactLastName'] as any);
        }
      }

      if (params['contactFirstName']) {
        if (Object.prototype.toString.call(params['contactFirstName']) === '[object Array]') {
          for (const item of params['contactFirstName']) {
            data.append('contactFirstName', item as any);
          }
        } else {
          data.append('contactFirstName', params['contactFirstName'] as any);
        }
      }

      if (params['brandCode']) {
        if (Object.prototype.toString.call(params['brandCode']) === '[object Array]') {
          for (const item of params['brandCode']) {
            data.append('brandCode', item as any);
          }
        } else {
          data.append('brandCode', params['brandCode'] as any);
        }
      }

      if (params['companyId']) {
        if (Object.prototype.toString.call(params['companyId']) === '[object Array]') {
          for (const item of params['companyId']) {
            data.append('companyId', item as any);
          }
        } else {
          data.append('companyId', params['companyId'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class RepositoriesService {
  /**
   *
   */
  static repositoriesControllerGetEmployeeStatuses(
    options: IRequestOptions = {}
  ): Promise<EmployeeStatus[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/employeeStatuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(EmployeeStatus, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetDiplomas(
    params: {
      /**  */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Diploma[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/diplomas';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Diploma, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetDrivingLicences(
    options: IRequestOptions = {}
  ): Promise<DrivingLicense[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/drivinglicenses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(DrivingLicense, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetHabilitations(
    params: {
      /**  */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Habilitation[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/habilitations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Habilitation, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetElectricHabilitations(
    options: IRequestOptions = {}
  ): Promise<ElectricVoltage[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/electricHabilitations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(ElectricVoltage, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetCaces(options: IRequestOptions = {}): Promise<CACESCategory[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/caces';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(CACESCategory, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetDrivingLicensesHabilitations(
    options: IRequestOptions = {}
  ): Promise<Habilitation[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/drivingLicensesHabilitations';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Habilitation, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetLanguages(
    options: IRequestOptions = {}
  ): Promise<LanguagesRepository> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/languages';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any) => resolve(plainToClass(LanguagesRepository, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static repositoriesControllerGetRisks(options: IRequestOptions = {}): Promise<Risk[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/risks';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Risk, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetSir(options: IRequestOptions = {}): Promise<SIR[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/sir';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(SIR, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetWorkingConditions(
    options: IRequestOptions = {}
  ): Promise<WorkingCondition[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/workingconditions';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(WorkingCondition, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static repositoriesControllerGetProtectionEquipments(
    params: {
      /**  */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProtectionEquipment[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/protectionEquipments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { type: params['type'] };
      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(ProtectionEquipment, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static repositoriesControllerGetBonusRateCadencies(
    options: IRequestOptions = {}
  ): Promise<BonusRateCadencies[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/bonusratecadencies';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(
        configs,
        (response: any[]) => resolve(plainToClass(BonusRateCadencies, response)),
        reject
      );
    });
  }
  /**
   *
   */
  static repositoriesControllerGetBonusRateTypes(
    options: IRequestOptions = {}
  ): Promise<BonusRateTypes[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/bonusratetypes';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(BonusRateTypes, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetSkills(
    params: {
      /**  */
      keyword?: string;
      /**  */
      type: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Skill[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/skills';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'], type: params['type'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Skill, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetSmic(options: IRequestOptions = {}): Promise<SMIC> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/smic';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any) => resolve(plainToClass(SMIC, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetSirOrigins(options: IRequestOptions = {}): Promise<SIROrigin[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/sirOrigins';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(SIROrigin, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetBonuses(options: IRequestOptions = {}): Promise<BonusPreset[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/bonuses';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(BonusPreset, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetDepartments(
    options: IRequestOptions = {}
  ): Promise<Department[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/departments';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Department, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetETesting(options: IRequestOptions = {}): Promise<ETest[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/ETesting';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(ETest, response)), reject);
    });
  }
  /**
   *
   */
  static repositoriesControllerGetCities(
    params: {
      /**  */
      keyword?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<City[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/repositories/cities';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { keyword: params['keyword'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(City, response)), reject);
    });
  }
}

export class QualificationsService {
  /**
   *
   */
  static qualificationsControllerGetSectors(
    params: {
      /**  */
      brandCode: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Sector[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications/sector';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { brandCode: params['brandCode'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Sector, response)), reject);
    });
  }
  /**
   *
   */
  static qualificationsControllerGetQualificationsBySectorId(
    params: {
      /**  */
      sectorId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications/sectorQualifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { sectorId: params['sectorId'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Qualification, response)), reject);
    });
  }
  /**
   *
   */
  static qualificationsControllerGetQualifications(
    params: {
      /**  */
      societyCode?: string;
      /**  */
      brand?: string;
      /**  */
      keyword: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Qualification[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        societyCode: params['societyCode'],
        brand: params['brand'],
        keyword: params['keyword'],
      };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Qualification, response)), reject);
    });
  }
  /**
   *
   */
  static qualificationsControllerGetQualificationRelatedSkills(
    params: {
      /**  */
      qualificationId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Skill[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/qualifications/{qualificationId}/skills';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { qualificationId: params['qualificationId'] };
      let data = null;

      configs.data = data;
      axios(configs, (response: any[]) => resolve(plainToClass(Skill, response)), reject);
    });
  }
}

export class OSMConsultantModelDtoBrandDto {
  /**  */
  'code'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['code'] = data['code'];
    this['name'] = data['name'];
  }
}

export class PerimeterCompany {
  /**  */
  'brand'?: OSMConsultantModelDtoBrandDto;

  /**  */
  'name': string;

  /**  */
  'societyId': string;

  /**  */
  'brandCodeApiHeader': string;

  /**  */
  'agencies': string[];

  constructor(data: undefined | any = {}) {
    this['brand'] = data['brand'];
    this['name'] = data['name'];
    this['societyId'] = data['societyId'];
    this['brandCodeApiHeader'] = data['brandCodeApiHeader'];
    this['agencies'] = data['agencies'];
  }
}

export class ConsultantPerimeter {
  /**  */
  'branchId'?: string;

  /**  */
  'regionId'?: string;

  /**  */
  'zoneId'?: string;

  /**  */
  'defaultAgencyId'?: string;

  /**  */
  'defaultCompanyId'?: string;

  /**  */
  'companies': PerimeterCompany[];

  constructor(data: undefined | any = {}) {
    this['branchId'] = data['branchId'];
    this['regionId'] = data['regionId'];
    this['zoneId'] = data['zoneId'];
    this['defaultAgencyId'] = data['defaultAgencyId'];
    this['defaultCompanyId'] = data['defaultCompanyId'];
    this['companies'] = data['companies'];
  }
}

export class EstablishmentAgency {
  /**  */
  'agencyId': string;

  /**  */
  'agencyTitle': string;

  constructor(data: undefined | any = {}) {
    this['agencyId'] = data['agencyId'];
    this['agencyTitle'] = data['agencyTitle'];
  }
}

export class EstablishmentZone {
  /**  */
  'zoneId': string;

  /**  */
  'zoneTitle': string;

  /**  */
  'agencies': EstablishmentAgency[];

  constructor(data: undefined | any = {}) {
    this['zoneId'] = data['zoneId'];
    this['zoneTitle'] = data['zoneTitle'];
    this['agencies'] = data['agencies'];
  }
}

export class EstablishmentRegion {
  /**  */
  'regionId': string;

  /**  */
  'regionTitle': string;

  /**  */
  'zones': EstablishmentZone[];

  constructor(data: undefined | any = {}) {
    this['regionId'] = data['regionId'];
    this['regionTitle'] = data['regionTitle'];
    this['zones'] = data['zones'];
  }
}

export class GetCompaniesBody {
  /**  */
  'keyword': string;

  /**  */
  'agencies': string[];

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['keyword'] = data['keyword'];
    this['agencies'] = data['agencies'];
    this['brandCode'] = data['brandCode'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyAddressDto {
  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'postalCode'?: string;

  /**  */
  'city'?: string;

  constructor(data: undefined | any = {}) {
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['postalCode'] = data['postalCode'];
    this['city'] = data['city'];
  }
}

export class OSMCommonModelDateDto {
  /**  */
  'year'?: number;

  /**  */
  'month'?: number;

  /**  */
  'day'?: number;

  constructor(data: undefined | any = {}) {
    this['year'] = data['year'];
    this['month'] = data['month'];
    this['day'] = data['day'];
  }
}

export class OSMCompanyModelSearchDtoVisitDto {
  /**  */
  'startDate'?: OSMCommonModelDateDto;

  /**  */
  'endDate'?: OSMCommonModelDateDto;

  constructor(data: undefined | any = {}) {
    this['startDate'] = data['startDate'];
    this['endDate'] = data['endDate'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class CompanySearchResult {
  /**  */
  'companyAddress'?: OSMCompanyModelCompanyDtoCompanyAddressDto;

  /**  */
  'lastVisit'?: OSMCompanyModelSearchDtoVisitDto;

  /**  */
  'hasFrameworkAgreement'?: boolean;

  /**  */
  'delegationPotential'?: number;

  /**  */
  'externalCode'?: string;

  /**  */
  'collectiveAgreement'?: OSMCompanyModelCompanyDtoCompanyCollectiveAgreementDto;

  /**  */
  'timeTrackingTool'?: OSMCompanyModelCompanyDtoCompanyTimeTrackingToolDto;

  /**  */
  'companyId'?: string;

  /**  */
  'companyName'?: string;

  /**  */
  'siret'?: string;

  constructor(data: undefined | any = {}) {
    this['companyAddress'] = data['companyAddress'];
    this['lastVisit'] = data['lastVisit'];
    this['hasFrameworkAgreement'] = data['hasFrameworkAgreement'];
    this['delegationPotential'] = data['delegationPotential'];
    this['externalCode'] = data['externalCode'];
    this['collectiveAgreement'] = data['collectiveAgreement'];
    this['timeTrackingTool'] = data['timeTrackingTool'];
    this['companyId'] = data['companyId'];
    this['companyName'] = data['companyName'];
    this['siret'] = data['siret'];
  }
}

export class OSMCompanyModelCompanyDtoNafDto {
  /**  */
  'ID'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['ID'] = data['ID'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoAgencyStrategyDto {
  /**  */
  'ID'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['ID'] = data['ID'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoCollectiveAgreementDto {
  /**  */
  'ID'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['ID'] = data['ID'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoEstablishmentTypeDto {
  /**  */
  'ID'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['ID'] = data['ID'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoGetCompanyAddressDto {
  /**  */
  'address1'?: string;

  /**  */
  'address2'?: string;

  /**  */
  'address3'?: string;

  /**  */
  'postalCode'?: string;

  /**  */
  'city'?: string;

  /**  */
  'latitude'?: number;

  /**  */
  'longitude'?: number;

  constructor(data: undefined | any = {}) {
    this['address1'] = data['address1'];
    this['address2'] = data['address2'];
    this['address3'] = data['address3'];
    this['postalCode'] = data['postalCode'];
    this['city'] = data['city'];
    this['latitude'] = data['latitude'];
    this['longitude'] = data['longitude'];
  }
}

export class OSMCompanyModelDtoCompanyAgencyPortfolioDto {
  /**  */
  'code'?: string;

  /**  */
  'societyCode'?: string;

  /**  */
  'portfolioType'?: string;

  constructor(data: undefined | any = {}) {
    this['code'] = data['code'];
    this['societyCode'] = data['societyCode'];
    this['portfolioType'] = data['portfolioType'];
  }
}

export class OSMCompanyModelDtoCompanySectorDto {
  /**  */
  'isPublic'?: boolean;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['isPublic'] = data['isPublic'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelFrameworkAgreementDtoFrameworkAgreementDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class CompanyDetails {
  /**  */
  'companyName'?: string;

  /**  */
  'siret'?: string;

  /**  */
  'NAF'?: OSMCompanyModelCompanyDtoNafDto;

  /**  */
  'acronym'?: string;

  /**  */
  'agencyStrategy'?: OSMCompanyModelCompanyDtoAgencyStrategyDto;

  /**  */
  'brandStrategy'?: string;

  /**  */
  'collectiveAgreement'?: OSMCompanyModelCompanyDtoCollectiveAgreementDto;

  /**  */
  'establishmentType'?: OSMCompanyModelCompanyDtoEstablishmentTypeDto;

  /**  */
  'portalFunction'?: boolean;

  /**  */
  'companyAddress'?: OSMCompanyModelCompanyDtoGetCompanyAddressDto;

  /**  */
  'companyOtherAddress'?: OSMCompanyModelCompanyDtoGetCompanyAddressDto[];

  /**  */
  'agencyPortfolios'?: OSMCompanyModelDtoCompanyAgencyPortfolioDto[];

  /**  */
  'solvency'?: string;

  /**  */
  'companySector'?: OSMCompanyModelDtoCompanySectorDto;

  /**  */
  'status'?: string;

  /**  */
  'servicesUri'?: string;

  /**  */
  'contactsUri'?: string;

  /**  */
  'practicalInformation'?: string;

  /**  */
  'frameworkAgreement'?: OSMCompanyModelFrameworkAgreementDtoFrameworkAgreementDto;

  /**  */
  'phone'?: string;

  /**  */
  'workforce'?: number;

  /**  */
  'isConsultantContact'?: boolean;

  constructor(data: undefined | any = {}) {
    this['companyName'] = data['companyName'];
    this['siret'] = data['siret'];
    this['NAF'] = data['NAF'];
    this['acronym'] = data['acronym'];
    this['agencyStrategy'] = data['agencyStrategy'];
    this['brandStrategy'] = data['brandStrategy'];
    this['collectiveAgreement'] = data['collectiveAgreement'];
    this['establishmentType'] = data['establishmentType'];
    this['portalFunction'] = data['portalFunction'];
    this['companyAddress'] = data['companyAddress'];
    this['companyOtherAddress'] = data['companyOtherAddress'];
    this['agencyPortfolios'] = data['agencyPortfolios'];
    this['solvency'] = data['solvency'];
    this['companySector'] = data['companySector'];
    this['status'] = data['status'];
    this['servicesUri'] = data['servicesUri'];
    this['contactsUri'] = data['contactsUri'];
    this['practicalInformation'] = data['practicalInformation'];
    this['frameworkAgreement'] = data['frameworkAgreement'];
    this['phone'] = data['phone'];
    this['workforce'] = data['workforce'];
    this['isConsultantContact'] = data['isConsultantContact'];
  }
}

export class CompanyAddress {
  /**  */
  'address'?: string;

  /**  */
  'cityName'?: string;

  /**  */
  'zipCode'?: string;

  /**  */
  'inseeCode'?: string;

  constructor(data: undefined | any = {}) {
    this['address'] = data['address'];
    this['cityName'] = data['cityName'];
    this['zipCode'] = data['zipCode'];
    this['inseeCode'] = data['inseeCode'];
  }
}

export class CompanyService {
  /**  */
  'serviceId'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['serviceId'] = data['serviceId'];
    this['name'] = data['name'];
  }
}

export class OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto {
  /**  */
  'id'?: number;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMCompanyModelCompanyDtoCompanyContactServicesDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class CompanyContact {
  /**  */
  'contactId'?: string;

  /**  */
  'firstName'?: string;

  /**  */
  'name'?: string;

  /**  */
  'position'?: string;

  /**  */
  'statut'?: string;

  /**  */
  'mobilePhoneNumber'?: string;

  /**  */
  'phone'?: string;

  /**  */
  'email'?: string;

  /**  */
  'isActiveOnCurrentCompany'?: boolean;

  /**  */
  'mainContact'?: boolean;

  /**  */
  'myContact'?: boolean;

  /**  */
  'dontMailMe'?: boolean;

  /**  */
  'dontCallMe'?: boolean;

  /**  */
  'dontPostMe'?: boolean;

  /**  */
  'dontPlaceMe'?: boolean;

  /**  */
  'civility'?: OSMRepositoriesModelCivilityTitleRepositoryDtoCivilityTitleDto;

  /**  */
  'services'?: OSMCompanyModelCompanyDtoCompanyContactServicesDto[];

  /**  */
  'portalAccess'?: boolean;

  constructor(data: undefined | any = {}) {
    this['contactId'] = data['contactId'];
    this['firstName'] = data['firstName'];
    this['name'] = data['name'];
    this['position'] = data['position'];
    this['statut'] = data['statut'];
    this['mobilePhoneNumber'] = data['mobilePhoneNumber'];
    this['phone'] = data['phone'];
    this['email'] = data['email'];
    this['isActiveOnCurrentCompany'] = data['isActiveOnCurrentCompany'];
    this['mainContact'] = data['mainContact'];
    this['myContact'] = data['myContact'];
    this['dontMailMe'] = data['dontMailMe'];
    this['dontCallMe'] = data['dontCallMe'];
    this['dontPostMe'] = data['dontPostMe'];
    this['dontPlaceMe'] = data['dontPlaceMe'];
    this['civility'] = data['civility'];
    this['services'] = data['services'];
    this['portalAccess'] = data['portalAccess'];
  }
}

export class OSMCommonModelLocationModelOutputLocationDto {
  /**  */
  'address'?: string;

  /**  */
  'cityName'?: string;

  /**  */
  'zipCode'?: string;

  /**  */
  'inseeCode'?: string;

  constructor(data: undefined | any = {}) {
    this['address'] = data['address'];
    this['cityName'] = data['cityName'];
    this['zipCode'] = data['zipCode'];
    this['inseeCode'] = data['inseeCode'];
  }
}

export class PositionStudyCompany {
  /**  */
  'companyId'?: string;

  /**  */
  'name'?: string;

  /**  */
  'location'?: OSMCommonModelLocationModelOutputLocationDto;

  /**  */
  'siret'?: string;

  constructor(data: undefined | any = {}) {
    this['companyId'] = data['companyId'];
    this['name'] = data['name'];
    this['location'] = data['location'];
    this['siret'] = data['siret'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoQualificationDto {
  /**  */
  'id'?: string;

  /**  */
  'shortLabel'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['shortLabel'] = data['shortLabel'];
    this['label'] = data['label'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto {
  /**  */
  'sequence'?: string;

  /**  */
  'label'?: string;

  /**  */
  'labelPriority'?: string;

  /**  */
  'typeCode'?: string;

  /**  */
  'id'?: string;

  /**  */
  'priorityId'?: number;

  constructor(data: undefined | any = {}) {
    this['sequence'] = data['sequence'];
    this['label'] = data['label'];
    this['labelPriority'] = data['labelPriority'];
    this['typeCode'] = data['typeCode'];
    this['id'] = data['id'];
    this['priorityId'] = data['priorityId'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetDrivingLicenseDto {
  /**  */
  'sequence'?: number;

  /**  */
  'label'?: string;

  /**  */
  'labelPriority'?: string;

  /**  */
  'id': string;

  /**  */
  'priorityId': number;

  constructor(data: undefined | any = {}) {
    this['sequence'] = data['sequence'];
    this['label'] = data['label'];
    this['labelPriority'] = data['labelPriority'];
    this['id'] = data['id'];
    this['priorityId'] = data['priorityId'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseDto {
  /**  */
  'id'?: string;

  /**  */
  'status'?: string;

  /**  */
  'natureForeseeableRiskToPostList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  'followUpIndividualReinforcedList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  'workRequiringTrainingList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  'prohibitedWorksList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseListItemDto[];

  /**  */
  'drivingLicenseList'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetDrivingLicenseDto[];

  /**  */
  'welcomeById'?: string;

  /**  */
  'otherEquipment'?: string;

  /**  */
  'specialConditions'?: string;

  /**  */
  'instructionsToFollow'?: string;

  /**  */
  'painfulnessFlag'?: boolean;

  /**  */
  @Expose()
  @Type(() => Date)
  'implementedHardnessDate'?: Date;

  /**  */
  'habilitationAuthorizationDetail'?: string;

  /**  */
  'collectiveProtectionEquipment'?: string;

  /**  */
  'practice'?: number;

  /**  */
  'asbestosFlag'?: boolean;

  /**  */
  'otherFlag'?: boolean;

  /**  */
  'slingFlag'?: boolean;

  /**  */
  'superToleranceNoiseFlag'?: boolean;

  /**  */
  'helmetFlag'?: boolean;

  /**  */
  'shoesFlag'?: boolean;

  /**  */
  'requestDerogationProhibitedWorkFlag'?: boolean;

  /**  */
  'nilStateFlag'?: boolean;

  /**  */
  'companyWelcomeSheetFlag'?: boolean;

  /**  */
  'formalizationHomeFlag'?: boolean;

  /**  */
  'enhancedSecurityTrainingFlag'?: boolean;

  /**  */
  'glovesFlag'?: boolean;

  /**  */
  'habilitationAuthorizationRequiredFlag'?: boolean;

  /**  */
  'disabledFacilitiesFlag'?: boolean;

  /**  */
  'disabledTypoFlag'?: boolean;

  /**  */
  'languageFlag'?: boolean;

  /**  */
  'listRiskPositionFlag'?: boolean;

  /**  */
  'glassesFlag'?: boolean;

  /**  */
  'drivingLicenseFlag'?: boolean;

  /**  */
  'riskPositionFlag'?: boolean;

  /**  */
  'predictableNatureRiskFlag'?: boolean;

  /**  */
  'workAtHeightFlag'?: boolean;

  /**  */
  'workOnScaffoldingFlag'?: boolean;

  /**  */
  'prohibitedWorksFlag'?: boolean;

  /**  */
  'medicalSupervisionFlag'?: boolean;

  /**  */
  'trainingProvidedBy'?: string;

  /**  */
  'disabledFacilitiesDetail'?: string;

  /**  */
  'disabledTypoDetail'?: string;

  /**  */
  'natureOtherForeseeableRisks'?: string;

  /**  */
  'securityBusinessTest'?: string;

  /**  */
  'tutorOrRefereeOfTheWorker'?: string;

  /**  */
  'typeOfVibration'?: string;

  /**  */
  'jobEnvironment'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['status'] = data['status'];
    this['natureForeseeableRiskToPostList'] = data['natureForeseeableRiskToPostList'];
    this['followUpIndividualReinforcedList'] = data['followUpIndividualReinforcedList'];
    this['workRequiringTrainingList'] = data['workRequiringTrainingList'];
    this['prohibitedWorksList'] = data['prohibitedWorksList'];
    this['drivingLicenseList'] = data['drivingLicenseList'];
    this['welcomeById'] = data['welcomeById'];
    this['otherEquipment'] = data['otherEquipment'];
    this['specialConditions'] = data['specialConditions'];
    this['instructionsToFollow'] = data['instructionsToFollow'];
    this['painfulnessFlag'] = data['painfulnessFlag'];
    this['implementedHardnessDate'] = data['implementedHardnessDate'];
    this['habilitationAuthorizationDetail'] = data['habilitationAuthorizationDetail'];
    this['collectiveProtectionEquipment'] = data['collectiveProtectionEquipment'];
    this['practice'] = data['practice'];
    this['asbestosFlag'] = data['asbestosFlag'];
    this['otherFlag'] = data['otherFlag'];
    this['slingFlag'] = data['slingFlag'];
    this['superToleranceNoiseFlag'] = data['superToleranceNoiseFlag'];
    this['helmetFlag'] = data['helmetFlag'];
    this['shoesFlag'] = data['shoesFlag'];
    this['requestDerogationProhibitedWorkFlag'] = data['requestDerogationProhibitedWorkFlag'];
    this['nilStateFlag'] = data['nilStateFlag'];
    this['companyWelcomeSheetFlag'] = data['companyWelcomeSheetFlag'];
    this['formalizationHomeFlag'] = data['formalizationHomeFlag'];
    this['enhancedSecurityTrainingFlag'] = data['enhancedSecurityTrainingFlag'];
    this['glovesFlag'] = data['glovesFlag'];
    this['habilitationAuthorizationRequiredFlag'] = data['habilitationAuthorizationRequiredFlag'];
    this['disabledFacilitiesFlag'] = data['disabledFacilitiesFlag'];
    this['disabledTypoFlag'] = data['disabledTypoFlag'];
    this['languageFlag'] = data['languageFlag'];
    this['listRiskPositionFlag'] = data['listRiskPositionFlag'];
    this['glassesFlag'] = data['glassesFlag'];
    this['drivingLicenseFlag'] = data['drivingLicenseFlag'];
    this['riskPositionFlag'] = data['riskPositionFlag'];
    this['predictableNatureRiskFlag'] = data['predictableNatureRiskFlag'];
    this['workAtHeightFlag'] = data['workAtHeightFlag'];
    this['workOnScaffoldingFlag'] = data['workOnScaffoldingFlag'];
    this['prohibitedWorksFlag'] = data['prohibitedWorksFlag'];
    this['medicalSupervisionFlag'] = data['medicalSupervisionFlag'];
    this['trainingProvidedBy'] = data['trainingProvidedBy'];
    this['disabledFacilitiesDetail'] = data['disabledFacilitiesDetail'];
    this['disabledTypoDetail'] = data['disabledTypoDetail'];
    this['natureOtherForeseeableRisks'] = data['natureOtherForeseeableRisks'];
    this['securityBusinessTest'] = data['securityBusinessTest'];
    this['tutorOrRefereeOfTheWorker'] = data['tutorOrRefereeOfTheWorker'];
    this['typeOfVibration'] = data['typeOfVibration'];
    this['jobEnvironment'] = data['jobEnvironment'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoServiceDto {
  /**  */
  'id'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
  }
}

export class PositionStudy {
  /**  */
  'positionStudyId': string;

  /**  */
  'positionStudyName'?: string;

  /**  */
  'agencyId': string;

  /**  */
  'completionStatus'?: EnumPositionStudyCompletionStatus;

  /**  */
  'company'?: PositionStudyCompany;

  /**  */
  'qualification'?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  'mase'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetMaseDto;

  /**  */
  @Expose()
  @Type(() => Date)
  'modificationDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'creationDate'?: Date;

  /**  */
  'service'?: OSMMissionsWithoutScheduleModelDtoServiceDto;

  constructor(data: undefined | any = {}) {
    this['positionStudyId'] = data['positionStudyId'];
    this['positionStudyName'] = data['positionStudyName'];
    this['agencyId'] = data['agencyId'];
    this['completionStatus'] = data['completionStatus'];
    this['company'] = data['company'];
    this['qualification'] = data['qualification'];
    this['mase'] = data['mase'];
    this['modificationDate'] = data['modificationDate'];
    this['creationDate'] = data['creationDate'];
    this['service'] = data['service'];
  }
}

export class PositionStudiesWithStatistics {
  /**  */
  'positionStudies': PositionStudy[];

  /**  */
  'upToDateCount': number;

  /**  */
  'pendingUpdateCount': number;

  /**  */
  'missingInfoCount': number;

  /**  */
  'riskyUpToDateCount': number;

  /**  */
  'riskyPendingUpdateCount': number;

  /**  */
  'riskyMissingInfoCount': number;

  /**  */
  'count'?: number;

  constructor(data: undefined | any = {}) {
    this['positionStudies'] = data['positionStudies'];
    this['upToDateCount'] = data['upToDateCount'];
    this['pendingUpdateCount'] = data['pendingUpdateCount'];
    this['missingInfoCount'] = data['missingInfoCount'];
    this['riskyUpToDateCount'] = data['riskyUpToDateCount'];
    this['riskyPendingUpdateCount'] = data['riskyPendingUpdateCount'];
    this['riskyMissingInfoCount'] = data['riskyMissingInfoCount'];
    this['count'] = data['count'];
  }
}

export class OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase {
  /**  */
  'companyId'?: string;

  /**  */
  'name'?: string;

  /**  */
  'location'?: OSMCommonModelLocationModelOutputLocationDto;

  constructor(data: undefined | any = {}) {
    this['companyId'] = data['companyId'];
    this['name'] = data['name'];
    this['location'] = data['location'];
  }
}

export class PositionStudyGeneralInformation {
  /**  */
  'company'?: OSMMissionsWithoutScheduleModelDtoGetDtoGetCompanyDtoBase;

  /**  */
  'qualification'?: OSMMissionsWithoutScheduleModelDtoQualificationDto;

  /**  */
  'positionStudyName'?: string;

  /**  */
  'positionStudyId'?: string;

  /**  */
  @Expose()
  @Type(() => Date)
  'modificationDate'?: Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'creationDate'?: Date;

  /**  */
  'agencyId': string;

  constructor(data: undefined | any = {}) {
    this['company'] = data['company'];
    this['qualification'] = data['qualification'];
    this['positionStudyName'] = data['positionStudyName'];
    this['positionStudyId'] = data['positionStudyId'];
    this['modificationDate'] = data['modificationDate'];
    this['creationDate'] = data['creationDate'];
    this['agencyId'] = data['agencyId'];
  }
}

export class BonusCadencyItem {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class BonusItem {
  /**  */
  'id'?: string;

  /**  */
  'label'?: string;

  /**  */
  'amount'?: number;

  /**  */
  'cadency'?: BonusCadencyItem;

  /**  */
  'liable'?: boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['amount'] = data['amount'];
    this['cadency'] = data['cadency'];
    this['liable'] = data['liable'];
  }
}

export class Salary {
  /**  */
  'amounts': number[];

  /**  */
  'timeRate': EnumSalaryTimeRate;

  /**  */
  'isSmic': boolean;

  constructor(data: undefined | any = {}) {
    this['amounts'] = data['amounts'];
    this['timeRate'] = data['timeRate'];
    this['isSmic'] = data['isSmic'];
  }
}

export class WorkingHoursItem {
  /**  */
  'startTime': string;

  /**  */
  'endTime': string;

  /**  */
  'days': string[];

  constructor(data: undefined | any = {}) {
    this['startTime'] = data['startTime'];
    this['endTime'] = data['endTime'];
    this['days'] = data['days'];
  }
}

export class PositionStudyProfil {
  /**  */
  'positionStatus'?: string;

  /**  */
  'weekHours'?: number;

  /**  */
  'bonusRates'?: BonusItem[];

  /**  */
  'practice'?: number;

  /**  */
  'salary'?: Salary;

  /**  */
  'workingHours'?: WorkingHoursItem[];

  constructor(data: undefined | any = {}) {
    this['positionStatus'] = data['positionStatus'];
    this['weekHours'] = data['weekHours'];
    this['bonusRates'] = data['bonusRates'];
    this['practice'] = data['practice'];
    this['salary'] = data['salary'];
    this['workingHours'] = data['workingHours'];
  }
}

export class MaseItem {
  /**  */
  'id': string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class PositionStudyRisks {
  /**  */
  'otherRisks': string;

  /**  */
  'hasRisks': EnumPositionStudyRisksHasRisks;

  /**  */
  'risks': MaseItem[];

  constructor(data: undefined | any = {}) {
    this['otherRisks'] = data['otherRisks'];
    this['hasRisks'] = data['hasRisks'];
    this['risks'] = data['risks'];
  }
}

export class Language {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class PositionStudyLanguages {
  /**  */
  'requiredLanguages': Language[];

  /**  */
  'hasToSpeakOtherThanFrenchLanguage': EnumPositionStudyLanguagesHasToSpeakOtherThanFrenchLanguage;

  constructor(data: undefined | any = {}) {
    this['requiredLanguages'] = data['requiredLanguages'];
    this['hasToSpeakOtherThanFrenchLanguage'] = data['hasToSpeakOtherThanFrenchLanguage'];
  }
}

export class PositionStudyProtectionEquipments {
  /**  */
  'hasEpis': EnumPositionStudyProtectionEquipmentsHasEpis;

  /**  */
  'hasEpcs': EnumPositionStudyProtectionEquipmentsHasEpcs;

  /**  */
  'clientEpis': MaseItem[];

  /**  */
  'agencyEpis': MaseItem[];

  /**  */
  'mainEpcs': MaseItem[];

  /**  */
  'otherEpcs': string;

  constructor(data: undefined | any = {}) {
    this['hasEpis'] = data['hasEpis'];
    this['hasEpcs'] = data['hasEpcs'];
    this['clientEpis'] = data['clientEpis'];
    this['agencyEpis'] = data['agencyEpis'];
    this['mainEpcs'] = data['mainEpcs'];
    this['otherEpcs'] = data['otherEpcs'];
  }
}

export class PositionStudyHandicaps {
  /**  */
  'hasEstlablishedListOfRiskyPositions': EnumPositionStudyHandicapsHasEstlablishedListOfRiskyPositions;

  /**  */
  'isARiskyPosition': EnumPositionStudyHandicapsIsARiskyPosition;

  /**  */
  'needSir': EnumPositionStudyHandicapsNeedSir;

  /**  */
  'exposedToForbiddenWork': EnumPositionStudyHandicapsExposedToForbiddenWork;

  /**  */
  'exposedToCmrAgents': EnumPositionStudyHandicapsExposedToCmrAgents;

  /**  */
  'exposedToStreniousFactors': EnumPositionStudyHandicapsExposedToStreniousFactors;

  /**  */
  'incompatibleWithHandicaps': EnumPositionStudyHandicapsIncompatibleWithHandicaps;

  /**  */
  'handicapIncompatibilityDetail'?: string;

  /**  */
  'sirs': MaseItem[];

  /**  */
  'strenuousFactors': string[];

  /**  */
  'sirOrigin'?: string;

  constructor(data: undefined | any = {}) {
    this['hasEstlablishedListOfRiskyPositions'] = data['hasEstlablishedListOfRiskyPositions'];
    this['isARiskyPosition'] = data['isARiskyPosition'];
    this['needSir'] = data['needSir'];
    this['exposedToForbiddenWork'] = data['exposedToForbiddenWork'];
    this['exposedToCmrAgents'] = data['exposedToCmrAgents'];
    this['exposedToStreniousFactors'] = data['exposedToStreniousFactors'];
    this['incompatibleWithHandicaps'] = data['incompatibleWithHandicaps'];
    this['handicapIncompatibilityDetail'] = data['handicapIncompatibilityDetail'];
    this['sirs'] = data['sirs'];
    this['strenuousFactors'] = data['strenuousFactors'];
    this['sirOrigin'] = data['sirOrigin'];
  }
}

export class Skill {
  /**  */
  'id': string;

  /**  */
  'label': string;

  /**  */
  'typeCode': EnumSkillTypeCode;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['typeCode'] = data['typeCode'];
  }
}

export class PositionStudySkills {
  /**  */
  'skills': Skill[];

  /**  */
  'tasks'?: string;

  constructor(data: undefined | any = {}) {
    this['skills'] = data['skills'];
    this['tasks'] = data['tasks'];
  }
}

export class PositionStudyStudyLevel {
  /**  */
  'level': EnumPositionStudyStudyLevelLevel;

  /**  */
  'selected': boolean;

  /**  */
  'priority': EnumPositionStudyStudyLevelPriority;

  constructor(data: undefined | any = {}) {
    this['level'] = data['level'];
    this['selected'] = data['selected'];
    this['priority'] = data['priority'];
  }
}

export class PositionStudyDiploma {
  /**  */
  'priority': Priority;

  /**  */
  'id': string;

  /**  */
  'label'?: string;

  constructor(data: undefined | any = {}) {
    this['priority'] = data['priority'];
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class Sector {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class PositionStudyPracticalInfo {
  /**  */
  'workLocation'?: CompanyAddress;

  /**  */
  'isMobileWorkLocation'?: boolean;

  /**  */
  'service'?: CompanyService;

  /**  */
  'sector'?: Sector;

  /**  */
  'contactId'?: string;

  /**  */
  'jobInfo'?: string;

  constructor(data: undefined | any = {}) {
    this['workLocation'] = data['workLocation'];
    this['isMobileWorkLocation'] = data['isMobileWorkLocation'];
    this['service'] = data['service'];
    this['sector'] = data['sector'];
    this['contactId'] = data['contactId'];
    this['jobInfo'] = data['jobInfo'];
  }
}

export class Contact {
  /**  */
  'lastName': string;

  /**  */
  'firstName': string;

  /**  */
  'phone'?: string;

  constructor(data: undefined | any = {}) {
    this['lastName'] = data['lastName'];
    this['firstName'] = data['firstName'];
    this['phone'] = data['phone'];
  }
}

export class PositionStudyInstructions {
  /**  */
  'hasInstructionsToForward': boolean;

  /**  */
  'instructionToFollow': string;

  /**  */
  'hasHomeFormalisation': EnumPositionStudyInstructionsHasHomeFormalisation;

  /**  */
  'contactWelcomeToPost'?: string;

  /**  */
  'contactForFirstDay'?: Contact;

  constructor(data: undefined | any = {}) {
    this['hasInstructionsToForward'] = data['hasInstructionsToForward'];
    this['instructionToFollow'] = data['instructionToFollow'];
    this['hasHomeFormalisation'] = data['hasHomeFormalisation'];
    this['contactWelcomeToPost'] = data['contactWelcomeToPost'];
    this['contactForFirstDay'] = data['contactForFirstDay'];
  }
}

export class TestItem {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class PositionStudyTests {
  /**  */
  'eTests': TestItem[];

  /**  */
  'internTest'?: EnumPositionStudyTestsInternTest;

  constructor(data: undefined | any = {}) {
    this['eTests'] = data['eTests'];
    this['internTest'] = data['internTest'];
  }
}

export class DrivingLicense {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class PositionStudyLicenses {
  /**  */
  'hasLicense': EnumPositionStudyLicensesHasLicense;

  /**  */
  'licenses': DrivingLicense[];

  constructor(data: undefined | any = {}) {
    this['hasLicense'] = data['hasLicense'];
    this['licenses'] = data['licenses'];
  }
}

export class SkillWithPriority {
  /**  */
  'id': string;

  /**  */
  'label': string;

  /**  */
  'priority': Priority;

  /**  */
  'rank': number;

  /**  */
  'tasks'?: string[];

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['priority'] = data['priority'];
    this['rank'] = data['rank'];
    this['tasks'] = data['tasks'];
  }
}

export class ExplanationTime {
  /**  */
  'duration'?: string;

  /**  */
  'unit'?: EnumExplanationTimeUnit;

  constructor(data: undefined | any = {}) {
    this['duration'] = data['duration'];
    this['unit'] = data['unit'];
  }
}

export class LearningTime {
  /**  */
  'duration'?: string;

  /**  */
  'unit'?: EnumLearningTimeUnit;

  constructor(data: undefined | any = {}) {
    this['duration'] = data['duration'];
    this['unit'] = data['unit'];
  }
}

export class PositionStudyLearning {
  /**  */
  'skillsWithPriority'?: SkillWithPriority[];

  /**  */
  'cadenceDescription'?: string;

  /**  */
  'attendantProductivity'?: string;

  /**  */
  'workerProductivity'?: string;

  /**  */
  'explanationTime'?: ExplanationTime;

  /**  */
  'learningTime'?: LearningTime;

  /**  */
  'positionCriticality'?: EnumPositionStudyLearningPositionCriticality;

  constructor(data: undefined | any = {}) {
    this['skillsWithPriority'] = data['skillsWithPriority'];
    this['cadenceDescription'] = data['cadenceDescription'];
    this['attendantProductivity'] = data['attendantProductivity'];
    this['workerProductivity'] = data['workerProductivity'];
    this['explanationTime'] = data['explanationTime'];
    this['learningTime'] = data['learningTime'];
    this['positionCriticality'] = data['positionCriticality'];
  }
}

export class DetailedPositionStudy {
  /**  */
  'completionStatus'?: EnumDetailedPositionStudyCompletionStatus;

  /**  */
  'generalInformation': PositionStudyGeneralInformation;

  /**  */
  'profileSection': PositionStudyProfil;

  /**  */
  'risks': PositionStudyRisks;

  /**  */
  'languages': PositionStudyLanguages;

  /**  */
  'protectionEquipments': PositionStudyProtectionEquipments;

  /**  */
  'handicaps': PositionStudyHandicaps;

  /**  */
  'skillSection': PositionStudySkills;

  /**  */
  'studyLevels': PositionStudyStudyLevel[];

  /**  */
  'diplomas': PositionStudyDiploma[];

  /**  */
  'habilitations': MaseItem[];

  /**  */
  'practicalInformation': PositionStudyPracticalInfo;

  /**  */
  'instructions': PositionStudyInstructions;

  /**  */
  'tests': PositionStudyTests;

  /**  */
  'licenses': PositionStudyLicenses;

  /**  */
  'learning': PositionStudyLearning;

  constructor(data: undefined | any = {}) {
    this['completionStatus'] = data['completionStatus'];
    this['generalInformation'] = data['generalInformation'];
    this['profileSection'] = data['profileSection'];
    this['risks'] = data['risks'];
    this['languages'] = data['languages'];
    this['protectionEquipments'] = data['protectionEquipments'];
    this['handicaps'] = data['handicaps'];
    this['skillSection'] = data['skillSection'];
    this['studyLevels'] = data['studyLevels'];
    this['diplomas'] = data['diplomas'];
    this['habilitations'] = data['habilitations'];
    this['practicalInformation'] = data['practicalInformation'];
    this['instructions'] = data['instructions'];
    this['tests'] = data['tests'];
    this['licenses'] = data['licenses'];
    this['learning'] = data['learning'];
  }
}

export class Buffer {
  constructor(data: undefined | any = {}) {}
}

export class ClientPhotoRights {
  /**  */
  'companyId': string;

  /**  */
  'contactMail': string;

  /**  */
  'contactLastName': string;

  /**  */
  'consultantId': string;

  /**  */
  'contactFirstName'?: string;

  /**  */
  'originalname': string;

  /**  */
  'size': number;

  /**  */
  'encoding': string;

  /**  */
  'mimetype': string;

  /**  */
  'buffer': Buffer;

  /**  */
  @Expose()
  @Type(() => Date)
  'createdAt': Date;

  /**  */
  @Expose()
  @Type(() => Date)
  'updatedAt': Date;

  constructor(data: undefined | any = {}) {
    this['companyId'] = data['companyId'];
    this['contactMail'] = data['contactMail'];
    this['contactLastName'] = data['contactLastName'];
    this['consultantId'] = data['consultantId'];
    this['contactFirstName'] = data['contactFirstName'];
    this['originalname'] = data['originalname'];
    this['size'] = data['size'];
    this['encoding'] = data['encoding'];
    this['mimetype'] = data['mimetype'];
    this['buffer'] = data['buffer'];
    this['createdAt'] = data['createdAt'];
    this['updatedAt'] = data['updatedAt'];
  }
}

export class HasClientGivenRights {
  /**  */
  'hasClientGivenRights': boolean;

  /**  */
  'photoRights'?: ClientPhotoRights;

  constructor(data: undefined | any = {}) {
    this['hasClientGivenRights'] = data['hasClientGivenRights'];
    this['photoRights'] = data['photoRights'];
  }
}

export class CreatePositionStudyParams {
  /**  */
  'positionStudyName': string;

  /**  */
  'qualificationId': string;

  /**  */
  'companyId': string;

  /**  */
  'agencyId': string;

  /**  */
  'brandCode': string;

  /**  */
  'sector'?: Sector;

  constructor(data: undefined | any = {}) {
    this['positionStudyName'] = data['positionStudyName'];
    this['qualificationId'] = data['qualificationId'];
    this['companyId'] = data['companyId'];
    this['agencyId'] = data['agencyId'];
    this['brandCode'] = data['brandCode'];
    this['sector'] = data['sector'];
  }
}

export class PositionStudyGeneralInformationBody {
  /**  */
  'positionStudyName': string;

  /**  */
  'qualificationId': string;

  /**  */
  'companyId': string;

  /**  */
  'agencyId': string;

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['positionStudyName'] = data['positionStudyName'];
    this['qualificationId'] = data['qualificationId'];
    this['companyId'] = data['companyId'];
    this['agencyId'] = data['agencyId'];
    this['brandCode'] = data['brandCode'];
  }
}

export class UpdatePositionStudyBody {
  /**  */
  'generalInformation': PositionStudyGeneralInformationBody;

  /**  */
  'profileSection': PositionStudyProfil;

  /**  */
  'practicalInformation': PositionStudyPracticalInfo;

  /**  */
  'languages': PositionStudyLanguages;

  /**  */
  'risks': PositionStudyRisks;

  /**  */
  'protectionEquipments': PositionStudyProtectionEquipments;

  /**  */
  'handicaps': PositionStudyHandicaps;

  /**  */
  'skillSection': PositionStudySkills;

  /**  */
  'studyLevels': PositionStudyStudyLevel[];

  /**  */
  'diplomas': PositionStudyDiploma[];

  /**  */
  'habilitations': MaseItem[];

  /**  */
  'instructions': PositionStudyInstructions;

  /**  */
  'tests': PositionStudyTests;

  /**  */
  'licenses': PositionStudyLicenses;

  /**  */
  'learning': PositionStudyLearning;

  /**  */
  'triggeredByRisksTrippleButton'?: boolean;

  constructor(data: undefined | any = {}) {
    this['generalInformation'] = data['generalInformation'];
    this['profileSection'] = data['profileSection'];
    this['practicalInformation'] = data['practicalInformation'];
    this['languages'] = data['languages'];
    this['risks'] = data['risks'];
    this['protectionEquipments'] = data['protectionEquipments'];
    this['handicaps'] = data['handicaps'];
    this['skillSection'] = data['skillSection'];
    this['studyLevels'] = data['studyLevels'];
    this['diplomas'] = data['diplomas'];
    this['habilitations'] = data['habilitations'];
    this['instructions'] = data['instructions'];
    this['tests'] = data['tests'];
    this['licenses'] = data['licenses'];
    this['learning'] = data['learning'];
    this['triggeredByRisksTrippleButton'] = data['triggeredByRisksTrippleButton'];
  }
}

export class UpdatePositionStudyParams {
  /**  */
  'positionStudyId': string;

  /**  */
  'positionStudyBody': UpdatePositionStudyBody;

  constructor(data: undefined | any = {}) {
    this['positionStudyId'] = data['positionStudyId'];
    this['positionStudyBody'] = data['positionStudyBody'];
  }
}

export class DeletePositionStudyParams {
  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['brandCode'] = data['brandCode'];
  }
}

export class UpdatePositionStudyNameBody {
  /**  */
  'positionStudyName': string;

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['positionStudyName'] = data['positionStudyName'];
    this['brandCode'] = data['brandCode'];
  }
}

export class DuplicateFullEDPBody {
  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['brandCode'] = data['brandCode'];
  }
}

export class DuplicateEDPWithNewQualificationBody {
  /**  */
  'positionStudyName': string;

  /**  */
  'qualificationId': string;

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['positionStudyName'] = data['positionStudyName'];
    this['qualificationId'] = data['qualificationId'];
    this['brandCode'] = data['brandCode'];
  }
}

export class PositionStudyFile {
  /**  */
  'id': string;

  /**  */
  'name': string;

  /**  */
  'size': number;

  /**  */
  'path': string;

  /**  */
  'encoding': string;

  /**  */
  'mimetype': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['name'] = data['name'];
    this['size'] = data['size'];
    this['path'] = data['path'];
    this['encoding'] = data['encoding'];
    this['mimetype'] = data['mimetype'];
  }
}

export class AuthenticatedUser {
  /**  */
  'givenName'?: string;

  /**  */
  'familyName'?: string;

  /**  */
  'siid'?: string;

  /**  */
  'email'?: string;

  constructor(data: undefined | any = {}) {
    this['givenName'] = data['givenName'];
    this['familyName'] = data['familyName'];
    this['siid'] = data['siid'];
    this['email'] = data['email'];
  }
}

export class GetPositionStudyPdfQueryParams {
  /**  */
  'user': AuthenticatedUser;

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['user'] = data['user'];
    this['brandCode'] = data['brandCode'];
  }
}

export class GetPreventionSheetPdfQueryParams {
  /**  */
  'user': AuthenticatedUser;

  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['user'] = data['user'];
    this['brandCode'] = data['brandCode'];
  }
}

export class GetSynthesisSheetPdfQueryParams {
  /**  */
  'brandCode': string;

  constructor(data: undefined | any = {}) {
    this['brandCode'] = data['brandCode'];
  }
}

export class GetPositionStudiesStatisticsParams {
  /**  */
  'agencyIds': string[];

  constructor(data: undefined | any = {}) {
    this['agencyIds'] = data['agencyIds'];
  }
}

export class PositionStudiesStatistics {
  /**  */
  'missingInfo': number;

  /**  */
  'pendingUpdate': number;

  /**  */
  'upToDate': number;

  /**  */
  'total': number;

  constructor(data: undefined | any = {}) {
    this['missingInfo'] = data['missingInfo'];
    this['pendingUpdate'] = data['pendingUpdate'];
    this['upToDate'] = data['upToDate'];
    this['total'] = data['total'];
  }
}

export class EmployeeStatus {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class Diploma {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class Habilitation {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class Current {
  /**  */
  'electricHabilitation': Habilitation;

  /**  */
  'key': string;

  constructor(data: undefined | any = {}) {
    this['electricHabilitation'] = data['electricHabilitation'];
    this['key'] = data['key'];
  }
}

export class ElectricHabilitationsCategory {
  /**  */
  'categoryLabel': string;

  /**  */
  'categoryId': string;

  /**  */
  'currents': Current[];

  constructor(data: undefined | any = {}) {
    this['categoryLabel'] = data['categoryLabel'];
    this['categoryId'] = data['categoryId'];
    this['currents'] = data['currents'];
  }
}

export class ElectricVoltage {
  /**  */
  'currentId': string;

  /**  */
  'value': string;

  /**  */
  'levels': ElectricHabilitationsCategory[];

  constructor(data: undefined | any = {}) {
    this['currentId'] = data['currentId'];
    this['value'] = data['value'];
    this['levels'] = data['levels'];
  }
}

export class CACESCategory {
  /**  */
  'categoryLabel': string;

  /**  */
  'categoryId': string;

  /**  */
  'caces': Habilitation[];

  constructor(data: undefined | any = {}) {
    this['categoryLabel'] = data['categoryLabel'];
    this['categoryId'] = data['categoryId'];
    this['caces'] = data['caces'];
  }
}

export class LanguagesRepository {
  /**  */
  'languages': Language[];

  /**  */
  'mostUsedLanguages': Language[];

  /**  */
  'french'?: Language;

  constructor(data: undefined | any = {}) {
    this['languages'] = data['languages'];
    this['mostUsedLanguages'] = data['mostUsedLanguages'];
    this['french'] = data['french'];
  }
}

export class Risk {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class SIR {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class WorkingCondition {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class ProtectionEquipment {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class BonusRateCadencies {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class BonusRateTypes {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class OSMRepositoriesModelSmicRepositoryDtoSmicDto {
  /**  */
  'hourlyAmount'?: number;

  /**  */
  'monthlyAmount'?: number;

  /**  */
  'annualAmount'?: number;

  constructor(data: undefined | any = {}) {
    this['hourlyAmount'] = data['hourlyAmount'];
    this['monthlyAmount'] = data['monthlyAmount'];
    this['annualAmount'] = data['annualAmount'];
  }
}

export class SMIC {
  /**  */
  'smic': OSMRepositoriesModelSmicRepositoryDtoSmicDto;

  constructor(data: undefined | any = {}) {
    this['smic'] = data['smic'];
  }
}

export class SIROrigin {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class BonusPreset {
  /**  */
  'id': string;

  /**  */
  'title': string;

  /**  */
  'frequency': EnumBonusPresetFrequency;

  /**  */
  'maxAmount'?: number;

  /**  */
  'liable': boolean;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['title'] = data['title'];
    this['frequency'] = data['frequency'];
    this['maxAmount'] = data['maxAmount'];
    this['liable'] = data['liable'];
  }
}

export class Department {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class ETest {
  /**  */
  'id': string;

  /**  */
  'label': string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
  }
}

export class City {
  /**  */
  'uId'?: string;

  /**  */
  'insee'?: string;

  /**  */
  'zipCode'?: string;

  /**  */
  'name'?: string;

  constructor(data: undefined | any = {}) {
    this['uId'] = data['uId'];
    this['insee'] = data['insee'];
    this['zipCode'] = data['zipCode'];
    this['name'] = data['name'];
  }
}

export class Qualification {
  /**  */
  'id': string;

  /**  */
  'label': string;

  /**  */
  'shortLabel'?: string;

  constructor(data: undefined | any = {}) {
    this['id'] = data['id'];
    this['label'] = data['label'];
    this['shortLabel'] = data['shortLabel'];
  }
}
export enum EnumPositionStudyCompletionStatus {
  'UP_TO_DATE' = 'UP_TO_DATE',
  'MISSING_INFO' = 'MISSING_INFO',
  'PENDING_UPDATE' = 'PENDING_UPDATE',
}
export enum EnumSalaryTimeRate {
  'horaire' = 'horaire',
  'mensuelle' = 'mensuelle',
  'annuelle' = 'annuelle',
}
export enum EnumPositionStudyRisksHasRisks {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyLanguagesHasToSpeakOtherThanFrenchLanguage {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyProtectionEquipmentsHasEpis {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyProtectionEquipmentsHasEpcs {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyHandicapsHasEstlablishedListOfRiskyPositions {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyHandicapsIsARiskyPosition {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyHandicapsNeedSir {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyHandicapsExposedToForbiddenWork {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyHandicapsExposedToCmrAgents {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyHandicapsExposedToStreniousFactors {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyHandicapsIncompatibleWithHandicaps {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumSkillTypeCode {
  'TECHNIQUES_ET_PROCEDES' = 'TECHNIQUES_ET_PROCEDES',
  'INFORMATIQUE' = 'INFORMATIQUE',
  'MATERIAUX_ET_PRODUITS' = 'MATERIAUX_ET_PRODUITS',
  'MACHINES_ET_MATERIELS' = 'MACHINES_ET_MATERIELS',
  'ENVIRONNEMENT_ET_TYPOLOGIES' = 'ENVIRONNEMENT_ET_TYPOLOGIES',
  'COMPETENCES_ET_TACHES_A_EFFECTUER' = 'COMPETENCES_ET_TACHES_A_EFFECTUER',
}
export enum EnumPositionStudyStudyLevelLevel {
  'CAP_BEP' = 'CAP_BEP',
  'BAC' = 'BAC',
  'BAC_PLUS_2' = 'BAC_PLUS_2',
  'BAC_PLUS_3' = 'BAC_PLUS_3',
  'BAC_PLUS_5' = 'BAC_PLUS_5',
}
export enum EnumPositionStudyStudyLevelPriority {
  'UNKNOWN' = 'UNKNOWN',
  'OPTIONAL' = 'OPTIONAL',
  'MANDATORY' = 'MANDATORY',
}
export enum Priority {
  'MANDATORY' = 'MANDATORY',
  'WISHED' = 'WISHED',
  'OPTIONAL' = 'OPTIONAL',
}
export enum EnumPositionStudyInstructionsHasHomeFormalisation {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyTestsInternTest {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumPositionStudyLicensesHasLicense {
  'Yes' = 'Yes',
  'No' = 'No',
  'Unknown' = 'Unknown',
}
export enum EnumExplanationTimeUnit {
  'WEEKS' = 'WEEKS',
  'DAYS' = 'DAYS',
  'HOURS' = 'HOURS',
  'MINUTES' = 'MINUTES',
}
export enum EnumLearningTimeUnit {
  'WEEKS' = 'WEEKS',
  'DAYS' = 'DAYS',
  'HOURS' = 'HOURS',
  'MINUTES' = 'MINUTES',
}
export enum EnumPositionStudyLearningPositionCriticality {
  'SIMPLE' = 'SIMPLE',
  'SEMI_CRITICAL' = 'SEMI_CRITICAL',
  'CRITICAL' = 'CRITICAL',
}
export enum EnumDetailedPositionStudyCompletionStatus {
  'UP_TO_DATE' = 'UP_TO_DATE',
  'MISSING_INFO' = 'MISSING_INFO',
  'PENDING_UPDATE' = 'PENDING_UPDATE',
}
export enum EnumBonusPresetFrequency {
  '9ALIBskM0lsH3cVCJ1MEIct3mEuZNFxhldjZZ0Y1qMA1' = '9ALIBskM0lsH3cVCJ1MEIct3mEuZNFxhldjZZ0Y1qMA1',
  'j9xw9UlcvMEbbBY8Uh11pMt3mEuZNFxhldjZZ0Y1qMA1' = 'j9xw9UlcvMEbbBY8Uh11pMt3mEuZNFxhldjZZ0Y1qMA1',
  '8zlpn06sm9wpr6TXVVB89ct3mEuZNFxhldjZZ0Y1qMA1' = '8zlpn06sm9wpr6TXVVB89ct3mEuZNFxhldjZZ0Y1qMA1',
  'drERQqZiRC3G4wZGRqGOAMt3mEuZNFxhldjZZ0Y1qMA1' = 'drERQqZiRC3G4wZGRqGOAMt3mEuZNFxhldjZZ0Y1qMA1',
  '-bQBWUjPKS41g3wGk4aKb8t3mEuZNFxhldjZZ0Y1qMA1' = '-bQBWUjPKS41g3wGk4aKb8t3mEuZNFxhldjZZ0Y1qMA1',
  'fVHmiST8Gc7FEntxlhnQMct3mEuZNFxhldjZZ0Y1qMA1' = 'fVHmiST8Gc7FEntxlhnQMct3mEuZNFxhldjZZ0Y1qMA1',
  'spYW6-gqiN86hDI3SLwd4st3mEuZNFxhldjZZ0Y1qMA1' = 'spYW6-gqiN86hDI3SLwd4st3mEuZNFxhldjZZ0Y1qMA1',
}
