import { toast } from '@randstad-lean-mobile-factory/react-components-core';
import { useMutation, UseMutationOptions, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getAnalyticsUserInfo, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import {
  DetailedPositionStudy,
  PositionstudiesService,
  UpdatePositionStudyBody,
} from 'src/Services/API';
import { formatGeneralInformationForModification } from 'src/Utils/EDPGeneralInfoFormat';
import { formatProtectionEquipments } from 'src/Utils/formatPositionStudy';

import { QueryKeys } from '../types';

export const useUpdatePositionStudy = (
  extraOptions?: UseMutationOptions<DetailedPositionStudy, AxiosError, unknown>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  const userInfo = useSelector(getAnalyticsUserInfo);

  const queryClient = useQueryClient();

  return useMutation(
    [QueryKeys.updatePositionStudy],
    async (params: {
      initialPositionStudy: DetailedPositionStudy;
      modification: Partial<UpdatePositionStudyBody>;
    }) => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      const positionStudy =
        await PositionstudiesService.positionStudiesControllerUpdatePositionStudy({
          body: {
            positionStudyId: params.initialPositionStudy.generalInformation.positionStudyId ?? '',
            positionStudyBody: {
              ...params.initialPositionStudy,
              ...params.modification,
              protectionEquipments: formatProtectionEquipments(
                params.modification.protectionEquipments ??
                  params.initialPositionStudy.protectionEquipments
              ),
              generalInformation: formatGeneralInformationForModification(
                params.initialPositionStudy.generalInformation,
                currentBrand.brandCodeApiHeader
              ),
            },
          },
        });
      ReactGA.event("Modification d'une EDP", {
        ...userInfo,
      });

      return positionStudy;
    },
    {
      ...extraOptions,
      onSuccess: (positionStudy, variables, context) => {
        queryClient.invalidateQueries([QueryKeys.fetchPositionStudies]);
        queryClient.invalidateQueries([QueryKeys.fetchPositionStudiesStatistics]);
        if (positionStudy !== undefined) {
          queryClient.setQueryData(
            [
              'fetchPositionStudyById',
              positionStudy.generalInformation?.positionStudyId,
              currentBrand?.brandCodeApiHeader,
            ],
            positionStudy
          );
        }
        extraOptions?.onSuccess?.(positionStudy, variables, context);
      },
      onError: (error, variables, context) => {
        const errorMessage = error.response?.data.message;
        if (errorMessage?.includes('contacts')) {
          toast.error(errorMessage);
        }
        extraOptions?.onError?.(error, variables, context);
      },
    }
  );
};
