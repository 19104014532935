import { LeftArrow } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { getCompanyRoute, getStudiesRoute } from 'src/Services/Routing';

import { PositionStudyModificationContext } from '../PositionStudyModificationContext';

import styles from './BreadCrumb.module.scss';

const BreadCrumb = () => {
  const history = useHistory();
  const isMobile = useSelector(getDevice);
  const positionStudyModificationContext = useContext(PositionStudyModificationContext);
  const { data } = positionStudyModificationContext.fetchPositionStudy;
  const company = data?.generalInformation?.company;

  const items = isMobile
    ? []
    : [
        {
          title: 'accueil',
          onClick: () => {
            history.push(getStudiesRoute());
          },
        },
      ];

  if (company) {
    const { name, companyId } = company;
    items.push({
      title: `${name}`,
      onClick: () => {
        history.push({
          pathname: getCompanyRoute({ companyId }),
          state: {
            routeParameters: {
              companyId: companyId,
              companyName: name,
            },
          },
        });
      },
    });
  }

  return (
    <div className={styles.container}>
      {items.map(({ title, onClick }, index) => {
        return [
          <button key={title} className={styles.button} onClick={onClick}>
            {isMobile && <LeftArrow className={styles.leftArrow} />}
            <p className={styles.title}>{title}</p>
          </button>,
          index === items.length - 1 ? null : (
            <p key={`separator-${index}`} className={styles.separator}>
              /
            </p>
          ),
        ];
      })}
    </div>
  );
};

export default BreadCrumb;
