import { RandstadCircle, Settings } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Button, ParametersPopup } from '@randstad-lean-mobile-factory/react-components-core';
import { useState } from 'react';

import { PerimeterSelectionModal } from 'src/Containers/Modals/PerimeterSelectionModal';
import useAuth from 'src/Hooks/Authentication/useAuth';

const Parameters = () => {
  const [isOpened, setIsOpened] = useState(false);
  const { logout, user } = useAuth();

  const [isPerimeterSelectionOpen, setIsPerimeterSelectionOpen] = useState(false);

  return (
    <ParametersPopup
      trigger={
        <Button variant="tertiary" size="medium">
          <Settings variant={isOpened ? 'fill' : 'line'} />
        </Button>
      }
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      user={user}
      version={process.env.REACT_APP_VERSION && `${process.env.REACT_APP_VERSION}`}
      onLogout={() => logout({ returnTo: process.env.REACT_APP_AUTH0_REDIRECT_URI })}
    >
      <Button variant="tertiary" onClick={() => setIsPerimeterSelectionOpen(true)}>
        <RandstadCircle variant="fill" />
        choix de vos unités
      </Button>
      <PerimeterSelectionModal
        open={isPerimeterSelectionOpen}
        onClose={() => setIsPerimeterSelectionOpen(false)}
      />
    </ParametersPopup>
  );
};

export default Parameters;
