import { Marker, NavigateDown } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Badge, Button, Chip, Popover } from '@randstad-lean-mobile-factory/react-components-core';
import { useMemo } from 'react';

import styles from './PerimeterSelector.module.scss';
import { PerimeterSelectorProps } from './PerimeterSelector.types';
import { RegionSelector } from './RegionSelector';

export const PerimeterSelector = ({
  regions,
  selectedRegions,
  setSelectedRegions,
}: PerimeterSelectorProps) => {
  const selectedAgencies = useMemo(
    () => selectedRegions.flatMap(region => region.agencyIds).length,
    [selectedRegions]
  );

  return (
    <Popover
      trigger={
        <Chip size="large" rounded={false}>
          <Marker />
          périmètre
          <Badge color="currentColor">{selectedAgencies}</Badge>
          <NavigateDown />
        </Chip>
      }
      anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'left', vertical: 'top' }}
      className={styles.popover}
    >
      <div className={styles.topBar}>
        sélectionnez vos régions
        <Button variant="tertiary" size="small" onClick={() => setSelectedRegions([])}>
          réinitialiser
        </Button>
      </div>
      {regions.map(region => (
        <RegionSelector
          key={region.regionId}
          region={region}
          selectedRegions={selectedRegions}
          setSelectedRegions={setSelectedRegions}
        />
      ))}
    </Popover>
  );
};
