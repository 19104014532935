import { useSelector } from 'react-redux';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { getStudiesRoute, LocationState } from 'src/Services/Routing';

import { Dashboard } from '../Dashboard';
import { Studies } from '../Studies';

import styles from './MainPage.module.scss';
import MobileTopBar from './MobileTopBar';
import TopBar from './TopBar';

const MainPage = () => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const locationState = location.state;
  const background = locationState?.background;
  const isMobile = useSelector(getDevice);

  if (location.pathname === '/') history.replace(getStudiesRoute());

  return (
    <div className={styles.container}>
      {isMobile ? <MobileTopBar /> : <TopBar />}
      <Switch location={background || location}>
        <Route path="/studies" component={Studies} />
        <Route path="/dashboard" component={Dashboard} />
      </Switch>
    </div>
  );
};

export default MainPage;
