import { FindPeople, Position } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { Tab, Tabs } from '@randstad-lean-mobile-factory/react-components-core';
import { EDPIcon } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import { useHistory, useRouteMatch } from 'react-router-dom';

import { getDashboardRoute, getStudiesRoute } from 'src/Services/Routing';

import Apps from './Apps';
import Parameters from './Parameters';
import styles from './TopBar.module.scss';

const TopBar = () => {
  const history = useHistory();
  const route = useRouteMatch<{ route: 'studies' | 'dashboard' }>('/:route');

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <EDPIcon />
        <div className={styles.separator} />
        <h1 className={styles.title}>étude de poste</h1>
      </div>
      <div className={styles.content} data-theme="dark">
        <Tabs
          color="white"
          className={styles.tabs}
          size="medium"
          hideSeparator
          value={route?.params.route ?? 'studies'}
        >
          <Tab value="studies" onClick={() => history.push(getStudiesRoute())}>
            <Position variant={route?.params.route === 'studies' ? 'fill' : 'line'} />
            liste des edp
          </Tab>
          <Tab value="dashboard" onClick={() => history.push(getDashboardRoute())}>
            <FindPeople variant={route?.params.route === 'dashboard' ? 'fill' : 'line'} />
            pilotage
          </Tab>
        </Tabs>
        <Apps />
        <Parameters />
      </div>
    </div>
  );
};
export default TopBar;
