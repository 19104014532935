import { useAuth0 } from '@auth0/auth0-react';
import { useSelector } from 'react-redux';

import { useFetchPerimeter } from 'src/Hooks/Consultants/useFetchPerimeter';
import { getCurrentAgency, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';

export const useShowLoginScreen = () => {
  const { isAuthenticated } = useAuth0();
  const { data: perimeter } = useFetchPerimeter();

  const currentBrand = useSelector(getCurrentBrand);
  const currentAgency = useSelector(getCurrentAgency);

  return !isAuthenticated || !perimeter || !currentBrand || !currentAgency;
};
