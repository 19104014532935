import {
  ServerErrorRed,
  VidePasteque,
} from '@randstad-lean-mobile-factory/react-assets/dist/illustrations';
import { Loader } from '@randstad-lean-mobile-factory/react-components-core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFetchConsultantAgenciesByRegion } from 'src/Hooks/Consultants/useFetchConsultantAgenciesByRegion';
import { getCurrentAgency } from 'src/Redux/Perimeter/Selectors';

import styles from './Dashboard.module.scss';
import { GlobalStatistics } from './GlobalStatistics';
import { PerimeterSelector } from './PerimeterSelector';

export const Dashboard = () => {
  const agencyId = useSelector(getCurrentAgency);

  const { data: regions, isLoading } = useFetchConsultantAgenciesByRegion();
  const [selectedRegions, setSelectedRegions] = useState<
    { regionId: string; regionTitle: string; agencyIds: string[] }[]
  >([]);

  useEffect(() => {
    if (regions) {
      const region = regions.find(region =>
        region.zones.some(zone => zone.agencies.some(agency => agency.agencyId === agencyId))
      );
      if (region)
        setSelectedRegions([
          {
            regionId: region.regionId,
            regionTitle: region.regionTitle,
            agencyIds: region.zones.flatMap(zone => zone.agencies.map(agency => agency.agencyId)),
          },
        ]);
    }
  }, [agencyId, regions]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {isLoading ? (
          <div className={styles.illustration}>
            <Loader size="large" />
            chargement des études de poste
          </div>
        ) : !regions ? (
          <div className={styles.illustration}>
            <ServerErrorRed />
            désolé, une erreur s'est produite
          </div>
        ) : (
          <>
            <div>
              <PerimeterSelector
                regions={regions}
                selectedRegions={selectedRegions}
                setSelectedRegions={setSelectedRegions}
              />
            </div>
            {regions.length === 0 ? (
              <div className={styles.illustration}>
                <VidePasteque />
                veuillez sélectionner un périmètre
              </div>
            ) : (
              <GlobalStatistics regions={selectedRegions} />
            )}
          </>
        )}
      </div>
    </div>
  );
};
