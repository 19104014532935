export enum QueryKeys {
  fetchCompanies = 'fetchCompanies',
  fetchCompanyDetails = 'fetchCompanyDetails',
  fetchPerimeter = 'fetchPerimeter',
  fetchPositionStudies = 'fetchPositionStudies',
  fetchPositionStudiesCount = 'fetchPositionStudiesCount',
  fetchSector = 'fetchSector',
  fetchTopQualification = 'fetchTopQualification',
  fetchPositionStudyFromURL = 'fetchPositionStudyFromURL',
  fetchPositionStudyById = 'fetchPositionStudyById',
  fetchPositionStudyFiles = 'fetchPositionStudyFiles',
  updatePositionStudy = 'updatePositionStudy',
  fetchEmployeeStatuses = 'fetchEmployeeStatuses',
  fetchQualifications = 'fetchQualifications',
  fetchRisks = 'fetchRisks',
  fetchProtectionEquipments = 'fetchProtectionEquipments',
  fetchBonusRatesCadencies = 'fetchBonusRatesCadencies',
  fetchBonusRatesTypes = 'fetchBonusRatesTypes',
  fetchPresetBonuses = 'fetchPresetBonuses',
  fetchSkills = 'fetchSkills',
  fetchQualificationRelatedSkills = 'fetchQualificationRelatedSkills',
  fetchDiplomas = 'fetchDiplomas',
  fetchSMICAmount = 'fetchSMICAmount',
  fetchHabilitations = 'fetchHabilitations',
  useFetchLanguages = 'useFetchLanguages',
  fetchSIRs = 'fetchSIRs',
  fetchStrenuousFactors = 'fetchStrenuousFactors',
  fetchSIROrigins = 'fetchSIROrigins',
  fetchCaces = 'fetchCaces',
  fetchElectricHabilitations = 'fetchElectricHabilitations',
  fetchCompanyWorkingLocations = 'fetchCompanyWorkingLocations',
  fetchDepartments = 'fetchDepartments',
  fetchCompanyServices = 'fetchCompanyServices',
  fetchCompanyContacts = 'fetchCompanyContacts',
  fetchTestList = 'fetchTestList',
  fetchDrivingLicenses = 'fetchDrivingLicenses',
  fetchDrivingLicensesHabilitations = 'fetchDrivingLicensesHabilitations',
  fetchCities = 'fetchCities',
  fetchConsultantPhotoRights = 'fetchConsultantsPhotoRights',
  fetchCompanyPhotoRights = 'fetchCompanyPhotoRights',
  fetchAgenciesByRegion = 'fetchAgenciesByRegion',
  fetchPositionStudiesStatistics = 'fetchPositionStudiesStatistics',
}

export enum MutationKeys {
  createPositionStudy = 'createPositionStudy',
  fetchQualifications = 'fetchQualifications',
  updatePositionStudyName = 'updatePositionStudyName',
  duplicateEDP = 'duplicateEDP',
  duplicateEDPWithNewQualification = 'duplicateEDPWithNewQualification',
  uploadPositionStudyFiles = 'uploadPositionStudyFiles',
  deletePositionStudyFiles = 'deletePositionStudyFiles',
  fetchPositionStudyFileData = 'fetchPositionStudyFileData',
  generatePositionStudyPdf = 'generatePositionStudyPdf',
  generatePreventionSheetPdf = 'generatePreventionSheetPdf',
  generateSynthesisSheetPdf = 'generateSynthesisSheetPdf',
  giveConsultantPhotoRights = ' giveConsultantPhotoRights',
  giveCompanyPhotoRights = 'giveCompanyPhotoRights',
  deletePositionStudy = 'positionstudyDeletion',
}
