import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { Qualification, QualificationsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchTopQualifications = (
  sectorId: string,
  societyId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  extraOptions?: UseQueryOptions<Qualification[], unknown, Qualification[], any>
) => {
  const currentBrand = useSelector(getCurrentBrand);
  return useQuery(
    [QueryKeys.fetchTopQualification, currentBrand, sectorId, societyId],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return QualificationsService.qualificationsControllerGetQualificationsBySectorId({
        sectorId: sectorId,
      });
    },
    {
      staleTime: 86400000,
      enabled: currentBrand !== undefined,
      ...extraOptions,
    }
  );
};
