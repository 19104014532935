import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';

import { getAnalyticsUserInfo, getCurrentBrand } from 'src/Redux/Perimeter/Selectors';

import { MutationKeys, QueryKeys } from '../types';

import { positionStudyMutationsConfig } from './mutationConfigs';
import { useFetchPositionStudies } from './useFetchPositionStudies';

export const useDeletePositionStudy = () => {
  const mutation = useMutation<
    string,
    unknown,
    {
      positionStudyId: string;
      brandCode: string;
      queryClient: QueryClient;
    }
  >([MutationKeys.deletePositionStudy], positionStudyMutationsConfig['positionstudyDeletion']);
  const currentBrand = useSelector(getCurrentBrand);
  const queryClient = useQueryClient();
  const userInfo = useSelector(getAnalyticsUserInfo);

  return {
    deletePositionStudy: (positionStudyId: string) => {
      mutation.mutate({
        positionStudyId,
        brandCode: currentBrand?.brandCodeApiHeader ?? '',
        queryClient,
      });

      ReactGA.event("Suppression d'une EDP", {
        ...userInfo,
      });

      queryClient.setQueriesData(
        { queryKey: [QueryKeys.fetchPositionStudies], exact: false },
        (oldData: ReturnType<typeof useFetchPositionStudies>['data']) => {
          if (!oldData) return;
          return {
            ...oldData,
            pages:
              oldData.pages.map(page => ({
                ...page,
                data: page.positionStudies?.filter(
                  positionStudy => positionStudy.positionStudyId !== positionStudyId
                ),
                count:
                  page.count &&
                  page.positionStudies.some(
                    positionStudy => positionStudy.positionStudyId === positionStudyId
                  )
                    ? page.count - 1
                    : page.count,
              })) ?? [],
            pageParams: oldData?.pageParams ?? [],
          };
        }
      );
      queryClient.invalidateQueries([
        QueryKeys.fetchPositionStudies,
        QueryKeys.fetchPositionStudiesCount,
      ]);
    },
    mutation,
  };
};
