import { InterApp } from '@randstad-lean-mobile-factory/react-assets/dist/icons';
import { RandstadAppsPopup, Button } from '@randstad-lean-mobile-factory/react-components-core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useIsProductionEnvironment } from 'src/Hooks/Environment/useIsProductionEnvironment';
import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';

const Apps = () => {
  const { isProd } = useIsProductionEnvironment();
  const [isOpened, setIsOpened] = useState(false);
  const brand = useSelector(getCurrentBrand);
  return (
    <RandstadAppsPopup
      isProd={isProd}
      brandCode={brand?.brand?.code}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      onOpen={() => setIsOpened(true)}
      onClose={() => setIsOpened(false)}
      trigger={
        <Button variant="tertiary" size="medium">
          <InterApp variant={isOpened ? 'fill' : 'line'} />
        </Button>
      }
    />
  );
};

export default Apps;
