import {
  Button,
  FetchButton,
  ModalDeprecated,
  WithLightTitle,
} from '@randstad-lean-mobile-factory/react-components-core';
import moment from 'moment';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { IllusEDPCreation } from 'src/Assets';
import { getDevice } from 'src/Redux/UserPreferences/Selectors';
import { Qualification, Sector } from 'src/Services/API';
import { EDPCreationState } from 'src/Services/Routing';

import styles from './EDPCreationModal.module.scss';
import { EDP_CREATION_STEP, Props } from './EDPCreationModal.types';
import EDPNameSelectionModalComponent from './EDPNameSelectionModalComponent';
import EDPTopQualificationSelectionModalContent from './EDPTopQualificationSelectionModalContent';
import SectorSelectionModalContent from './SectorSelectionModalContent';

interface ModalChild {
  component: React.ReactNode;
  onPrevious?: () => void;
  onNext?: () => void;
  next?: EDP_CREATION_STEP;
  isValid: boolean;
  previous?: EDP_CREATION_STEP;
  rightSecondaryButtonText?: string;
}

const EDPCreationModal = (props: Props) => {
  const location = useLocation<EDPCreationState>();
  const history = useHistory();
  const [selectedSector, setSelectedSector] = useState<Sector | undefined>(undefined);
  const [selectedQualification, setSelectedQualification] = useState<Qualification | undefined>(
    undefined
  );
  const onClose = useCallback(() => {
    history.goBack();
  }, [history]);
  const isMobile = useSelector(getDevice);
  const [selectedName, setSelectedName] = useState('');
  const [step, setStep] = useState(EDP_CREATION_STEP.SECTOR_SELECTION);
  const ModalChildren: Record<EDP_CREATION_STEP, ModalChild> = {
    [EDP_CREATION_STEP.SECTOR_SELECTION]: {
      component: (
        <SectorSelectionModalContent
          selectedSector={selectedSector}
          onSectorChange={setSelectedSector}
          selectedQualification={selectedQualification}
          onQualificationChange={setSelectedQualification}
        />
      ),
      next:
        selectedQualification === undefined
          ? EDP_CREATION_STEP.QUALIFICATION_SELECTION
          : EDP_CREATION_STEP.NAME_SELECTION,
      onNext: () =>
        selectedQualification !== undefined &&
        setSelectedName(`${moment().format('DD/MM/YYYY')} ${selectedQualification.label}`),
      isValid: selectedSector !== undefined || selectedQualification !== undefined,
      rightSecondaryButtonText: 'annuler',
    },
    [EDP_CREATION_STEP.QUALIFICATION_SELECTION]: {
      component: (
        <EDPTopQualificationSelectionModalContent
          selectedSector={selectedSector}
          onQualificationChange={setSelectedQualification}
          selectedQualification={selectedQualification}
        />
      ),
      next: EDP_CREATION_STEP.NAME_SELECTION,
      onNext: () =>
        setSelectedName(`${moment().format('DD/MM/YYYY')} ${selectedQualification?.label}`),
      previous: EDP_CREATION_STEP.SECTOR_SELECTION,
      isValid: selectedQualification !== undefined,
      rightSecondaryButtonText: 'précédent',
    },
    [EDP_CREATION_STEP.NAME_SELECTION]: {
      component: (
        <EDPNameSelectionModalComponent
          selectedName={selectedName}
          onNameChange={setSelectedName}
        />
      ),
      previous:
        selectedSector !== undefined
          ? EDP_CREATION_STEP.QUALIFICATION_SELECTION
          : EDP_CREATION_STEP.SECTOR_SELECTION,
      isValid: selectedName !== undefined && selectedName.trim().length !== 0,
      onNext: () => {
        props.onValidate(selectedName, selectedQualification?.id ?? '', selectedSector);
      },
      rightSecondaryButtonText: 'précédent',
    },
  };

  const { component, next, previous, isValid, rightSecondaryButtonText, onPrevious, onNext } =
    ModalChildren[step];

  return (
    <ModalDeprecated
      open
      icon={<IllusEDPCreation />}
      title={`nouvelle étude de poste - ${step.valueOf() + 1}/3`}
      titleClassName={isMobile ? styles.mobileTitle : undefined}
      onClose={() => {
        onClose();
      }}
      footerActionsLeft={
        previous !== undefined ? [<Button.Tertiary onClick={onClose}>annuler</Button.Tertiary>] : []
      }
      footerActionsRight={[
        <Button.Secondary
          onClick={() => {
            previous !== undefined ? setStep(previous) : onClose();
            onPrevious?.();
          }}
        >
          {rightSecondaryButtonText}
        </Button.Secondary>,
        <>
          {next !== undefined ? (
            <Button
              disabled={!isValid}
              onClick={() => {
                isValid && setStep(next);
                onNext?.();
              }}
            >
              suivant
            </Button>
          ) : (
            <FetchButton
              disabled={!isValid}
              onClick={() => {
                isValid && onNext?.();
              }}
              title="valider"
              onSuccess={props.onSuccess}
              fetchStatus={props.fetchStatus}
            />
          )}
        </>,
      ]}
    >
      <WithLightTitle title="nom du client choisi">
        <div className={styles.companyName}>{location.state.modalParameters?.companyName}</div>
      </WithLightTitle>
      {component}
    </ModalDeprecated>
  );
};

export default EDPCreationModal;
