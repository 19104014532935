import { Button, ModalDeprecated } from '@randstad-lean-mobile-factory/react-components-core';
import { IllusTransfert, Refresh } from '@randstad-lean-mobile-factory/react-components-ui-shared';
import React, { useEffect, useState } from 'react';

import { useGenerateSynthesisSheetPdf } from 'src/Hooks/PositionStudies/useGenerateSynthesisSheetPdf';
import { onFileOpen } from 'src/Utils/onFileOpen';

import styles from './SynthesisSheetLoadingModal.module.scss';
import { Props } from './SynthesisSheetLoadingModal.types';
const SynthesisSheetLoadingModal = ({ positionStudyId, open, onClose }: Props) => {
  const [isReady, setIsReady] = useState(false);
  const [isError, setIsError] = useState(false);
  const [buffer, setBuffer] = useState<Buffer | null>(null);
  const generateSynthesisSheetPdf = useGenerateSynthesisSheetPdf();

  const close = () => {
    setIsReady(false);
    setIsError(false);
    setBuffer(null);
    onClose();
  };

  useEffect(() => {
    if (open) {
      generateSynthesisSheetPdf.mutate(positionStudyId ?? '', {
        onSuccess: resultBuffer => {
          setIsReady(true);
          setBuffer(resultBuffer);
        },
        onError: () => setIsError(true),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <ModalDeprecated
      open={open}
      title="génération de la synthèse du marché"
      onClose={close}
      icon={<IllusTransfert className={styles.icon} />}
      footerActionsRight={
        isReady && buffer
          ? [
              <Button.Secondary onClick={close}>annuler</Button.Secondary>,
              <Button
                text="valider"
                onClick={() => {
                  onFileOpen(buffer, 'application/pdf');
                  close();
                }}
              />,
            ]
          : []
      }
    >
      {!isReady && !isError && (
        <div className={styles.loadingText}>
          <Refresh className={styles.loading} />
          <div className={styles.text}>Votre document est en cours de fabrication...</div>
        </div>
      )}
      {isReady && (
        <div className={styles.text}>Votre document est prêt. Voulez-vous le consulter ?</div>
      )}
      {isError && (
        <div className={styles.text}>
          Impossible d'obtenir votre document. Veuillez réessayer plus tard.
        </div>
      )}
    </ModalDeprecated>
  );
};

export default SynthesisSheetLoadingModal;
