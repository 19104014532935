import { useQuery } from '@tanstack/react-query';

import { ConsultantsService } from 'src/Services/API';

import { QueryKeys } from '../types';

export const useFetchConsultantAgenciesByRegion = () => {
  return useQuery([QueryKeys.fetchAgenciesByRegion], async () => {
    return await ConsultantsService.consultantsControllerGetConsultantAgenciesByRegion();
  });
};
