import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { getCurrentBrand } from 'src/Redux/Perimeter/Selectors';
import { RepositoriesService, SMIC } from 'src/Services/API';

import { QueryKeys } from '../types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useFetchSMICAmount = (extraOptions?: UseQueryOptions<SMIC, unknown, SMIC, any>) => {
  const currentBrand = useSelector(getCurrentBrand);

  return useQuery(
    [QueryKeys.fetchSMICAmount, currentBrand],
    async () => {
      if (!currentBrand || !currentBrand.brandCodeApiHeader || !currentBrand.agencies) {
        return Promise.reject('No current brand or has an invalid current brand');
      }
      return RepositoriesService.repositoriesControllerGetSmic();
    },
    {
      staleTime: 86400000,
      enabled: currentBrand !== undefined,
      ...extraOptions,
    }
  );
};
